import React from 'react'
import moment from 'moment'

export default class Tools extends React.Component {
    static calculateWeekRange = ()  => {
        let now = new Date();
        now = moment(now);
        const dayName = moment(now).format('dddd');
        var day = Number(now.weekday());
        var start = now.subtract(day - 1, 'days').format('MM/DD/YYYY');
        var end = now.add(6, 'days').format('MM/DD/YYYY');
        if(dayName=='Sunday'){
          end = moment(start).format('MM/DD/YYYY');
          end = moment(end).subtract(1, 'days').format('MM/DD/YYYY');
          start = moment(end).subtract(6, 'days').format('MM/DD/YYYY'); 
        }
       
        return {start : start, end : end};
      }
    
    static getNextWeek = (data)  => {
        var start = moment(data.end).format('MM/DD/YYYY'); 
        start = moment(start).add(1, 'days').format('MM/DD/YYYY'); 
        var end = moment(start).add(6, 'days').format('MM/DD/YYYY'); 

        return {start : start, end : end}
    }
    
    static getPreviousWeek = (data) =>  {
        var end = moment(data.start).format('MM/DD/YYYY'); 
        end = moment(end).subtract(1, 'days').format('MM/DD/YYYY'); 
        var start = moment(end).subtract(6, 'days').format('MM/DD/YYYY'); 
        
        return {start : start, end : end}
    }
    
}