import React from 'react';
import '../../assets/timesheetTable.css';

export default class TimeSheetTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timesheet : [],
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.timesheet !== this.props.timesheet) {
            this.setState({timesheet : this.props.timesheet});
          }
      }
    
      
    render() {
        return ( 
            <>
                <table style = {{width: "100%"}}>
                    <thead style = {{"borderBottom":"solid #000000"}}>
                        
                        <th style = {{width : "20%"}}>Name</th>
                        <th style = {{width : "15%"}}>Status</th>
                        <th style = {{width : "9.2%"}}>Mon</th>
                        <th style = {{width : "9.2%"}}>Tues</th>
                        <th style = {{width : "9.2%"}}>Wed</th>
                        <th style = {{width : "9.2%"}}>Thur</th>
                        <th style = {{width : "9.2%"}}>Fri</th>
                        <th style = {{width : "9.2%"}}>Sat</th>
                        <th style = {{width : "9.2%"}}>Sun</th>
                    </thead>
                    <tbody >
                        {
                            this.state.timesheet.map(row =>
                                <>
                                    <tr className = "small">
                                        <td rowspan = "3"><small>{row.name}</small></td>
                                        <td><small>Approved</small></td>
                                        <td><small>{row.timesheet.approved[0]}</small></td>
                                        <td><small>{row.timesheet.approved[1]}</small></td>
                                        <td><small>{row.timesheet.approved[2]}</small></td>
                                        <td><small>{row.timesheet.approved[3]}</small></td>
                                        <td><small>{row.timesheet.approved[4]}</small></td>
                                        <td><small>{row.timesheet.approved[5]}</small></td>
                                        <td><small>{row.timesheet.approved[6]}</small></td>
                                    </tr>
                                    <tr className = "small">
                                        <td><small>Confirmed</small></td>
                                        <td><small>{row.timesheet.completed[0]}</small></td>
                                        <td><small>{row.timesheet.completed[1]}</small></td>
                                        <td><small>{row.timesheet.completed[2]}</small></td>
                                        <td><small>{row.timesheet.completed[3]}</small></td>
                                        <td><small>{row.timesheet.completed[4]}</small></td>
                                        <td><small>{row.timesheet.completed[5]}</small></td>
                                        <td><small>{row.timesheet.completed[6]}</small></td>
                                    </tr>
                                    <tr className = "small">
                                        <td><small>Completed</small></td>
                                        <td><small>{row.timesheet.confirmed[0]}</small></td>
                                        <td><small>{row.timesheet.confirmed[1]}</small></td>
                                        <td><small>{row.timesheet.confirmed[2]}</small></td>
                                        <td><small>{row.timesheet.confirmed[3]}</small></td>
                                        <td><small>{row.timesheet.confirmed[4]}</small></td>
                                        <td><small>{row.timesheet.confirmed[5]}</small></td>
                                        <td><small>{row.timesheet.confirmed[6]}</small></td>
                                    </tr>
                                </>
                            )
                        }
                    </tbody>
                </table>
            </>
        )
      }
}
