import React from "react"
import { Modal} from 'react-bootstrap';

import RosterController from '../../controllers/roster';

export default class ShiftModal extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            show:props.show,
            sil:props.sil
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show) {
            this.setState({show: this.props.show})
          }
        if (prevProps.sil !== this.props.sil) {
            this.setState({sil: this.props.sil})
          }
      }
      
    render(){
        return <Modal  size="xl" className="shiftModal participantShiftsModal" show={this.state.show} onHide={()=>this.props.close()} >
            <Modal.Header>
                <Modal.Title>{this.state.sil.length>0?`${this.state.sil[0].tblparticipant.lastname} ${this.state.sil[0].tblparticipant.firstname}`:``}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table   style={{marginTop:"1%"}} className="table table--roster table-borderless position-relative table-hover">
                    <thead style={{display:""}} className="thead-p-">
                        <tr className="tr-p"  style={{"borderBottom":"solid #000000"}}>
                            <th style={{width:"11%"}} className="table-col p-r" scope="col" >Shifts</th>
                            <th style={{width:"10%"}} className="table-col p-r" scope="col" >Time Interval</th>
                            <th style={{width:"10%"}} className="table-col p-r" scope="col" >Item Number</th>
                            <th style={{width:"33%"}} className="table-col p-r" scope="col" >Support Item Name</th>
                            <th style={{width:"5%"}} className="table-col p-r" scope="col" >Price</th>
                            <th style={{width:"10%"}} className="table-col p-r" scope="col" >Weekly Hrs</th>
                            <th style={{width:"6%"}} className="table-col p-r" scope="col" >$</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.sil.map(item=>
                            <tr>
                                <td className="p-r">{item.tblparticipantplaninclusions_shifttype?item.tblparticipantplaninclusions_shifttype.shifttype:"-"}</td>
                                <td className="p-r">{`${item.shiftday_range}-${item.shifttime_range}`}</td>
                                <td className="p-r">{item.tbl_support_service.item_number}</td>
                                <td className="p-r">{item.tbl_support_service.support_service}</td>
                                <td className="p-r">{item.unitprice}</td>
                                <td className="p-r">{item.weekly_hours}</td>
                                <td className="p-r">{Number(item.weekly_hours*item.unitprice).toFixed(2)}</td>
                            </tr>
                            )}
                    </tbody>
                </table>
                
            </Modal.Body>
            <Modal.Footer>
                <button className="btn-danger btn btn-small" onClick={()=>this.props.close()}>Close</button>
                <button className="btn-primary btn btn-small" onClick={()=>this.props.generateSILShifts(this.state.sil.length>0?this.state.sil[0].tblparticipant.id:null).then(res=>{RosterController.notifications({message:'<div class="text-success"><i class="bi bi-hand-thumbs-up-fill"></i> Successfully generated</div>'})
})}>Generate Shifts From Plan</button>
            </Modal.Footer>
        </Modal>
    }
}