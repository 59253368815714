import React from "react"
import '../../assets/settings.css';
import '../../assets/roster.css';
import momenttimezone from "moment-timezone";
import { Modal} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead'; 
import 'react-bootstrap-typeahead/css/Typeahead.css';
import SettingsController from '../../controllers/settings';
import RosterController from '../../controllers/roster';


export default class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            show:  props.show,
            data : props.data,
        }
        
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show) {
            this.setState({show: this.props.show})
          }
        if (prevProps.selectedEmployee !== this.props.selectedEmployee) {
            this.setState({selectedEmployee: this.props.selectedEmployee})
          }
        if (prevProps.data !== this.props.data) {
            this.setState({
                shift : this.props.data,
                starttime : this.props.data?.starttime,
                endtime : this.props.data?.endtime,
                clockin :this.props.data?.clockin,
                clockout : this.props.data?.clockout,
                address : this.props.data?.address,
            })
        }
      }
      
    render(){
        return <Modal  size = "l" className="fade" show={this.state.show} onHide={()=>{this.props.modalFuction()}} >
                    <Modal.Body>
                        <button type="button" className="close btn position-absolute end-0 top-0" onClick={()=>{this.props.modalFuction()}}>
                            &times;
                        </button>
                        <center className="start-50 m-2 h3">Edit Timesheet</center>
                        <hr></hr>
                        <form onSubmit = {(event) =>{event.preventDefault()}}>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label className = "text-sm" for="inputEmail4">Employee</label>
                                    <input  value = {this.state?.selectedEmployee?.name}  type="text" className="form-control small" id="inputEmail4" disabled/>
                                </div>
                                <div className="form-group col-3">
                                    <label className = "text-sm" for="inputPassword4">Start Time</label>
                                    <input onChange = {(event)=>this.setValue(event)} defaultValue = {this.state?.shift?.starttime} name = "starttime" type="time" className="form-control small" id="inputPassword4" />
                                </div>
                                <div className="form-group col-3">
                                    <label className = "text-sm" for="inputPassword4">End Time</label>
                                    <input onChange = {(event)=>this.setValue(event)} defaultValue = {this.state?.shift?.endtime} name = "endtime" type="time" className="form-control small" id="inputPassword4" />
                                </div>
                            </div>
                            <div className="form-group">
                                <label for="inputAddress" className = "text-sm">Shift Location</label>
                                <input onChange = {(event)=>this.setValue(event)} defaultValue = {this.state?.shift?.address} name ="address" type="text" className="form-control small" id="inputAddress"/>
                            </div>

                            <div className = "row border border-end-0 border-bottom-0 border-start-0" >
                                <div className="form-group col-3">
                                    <label className = "text-sm" for="inputPassword4">Clock In</label>
                                    <input onChange = {(event)=>this.setValue(event)} defaultValue = {this.state?.shift?.clockin} name = "clockin" type="time" className="form-control small" id="inputPassword4" />
                                </div>
                                <div className="form-group col-3">
                                    <label className = "text-sm" for="inputPassword4">Clock Out</label>
                                    <input onChange = {(event)=>this.setValue(event)} defaultValue = {this.state?.shift?.clockout} name = "clockout" type="time" className="form-control small" id="inputPassword4" />
                                </div>
                                <div className="form-group col">
                                    <label className = "text-sm" for="inputPassword4">Time Rounding</label>
                                    <div id="inputPassword4">
                                        <button type = "button" style = {{height : "23px"}} class=" btn pt-0 btn-sm btn-danger" id="inputPassword4">Round</button> <button type = "button" style = {{height : "23px"}} className=" btn pt-0 btn-sm btn-danger" id="inputPassword4">Over Time</button>
                                    </div>
                                </div>
                            </div>
                            <div className = "row float-end mt-4 ">
                                <div>
                                    <button type = "button" onClick = {()=>{
                                        RosterController.updateShift([{
                                            id : this.state.shift.id,
                                            starttime : this.state.starttime,
                                            endtime : this.state.endtime,
                                            clockin : this.state.clockin,
                                            clockout : this.state.clockout,
                                            address : this.state.address
                                        }]).then(res=>{
                                            this.props.updateShiftState();
                                            this.props.setSelectedShift({});
                                        })
                                        }} 
                                        style = {{height : "25px"}} className="btn btn-success pt-0">Save & Process</button>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
    }
}