import React from 'react';
import axios from "axios";
import date from "date-and-time"
import swal from 'sweetalert';





export default class RosterController extends React.Component {
    static extractToken() {
        var params = {};
        var parser = document.createElement('a');
        parser.href = window.location.href;
        var query = parser.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split('=');
          params[pair[0]] = decodeURIComponent(pair[1]);
        }
        const data = {
          token : params.token,
          api : params.api,
          main_system: params.main_system,
          name : params.name
        }
        return data;
    }

    static errorHandler = (error) =>{
      if (error.response) {
        console.log(error.response.data)
        RosterController.notifications({message:error.response.data.res, isError : true});
      } else if (error.request) {
        console.log(error.request)
        RosterController.notifications({message:`Failed to reach the server, check you nextwork`, isError : true});
      } else {
        console.log(error.message)
        RosterController.notifications({message: error.message, isError : true});
      }
    }

    static calculateDurationOfShift = (params) => {
        //THIS ACCEPTS START AND END TIME AND THEN CALCULATE DURATION 
        var starttime= date.parse(params.starttime.split(":")[0]+":"+params.starttime.split(":")[1], 'HH:mm:ss A',true); //PARSING START TIME FROM STRING TO DATE 
        var endtime =date.parse(params.endtime.split(":")[0]+":"+params.endtime.split(":")[1], 'HH:mm:ss A',true); //PARSING END TIMENT FROM STRING TO DATE
        var  duration = date.subtract(endtime, starttime).toHours(); //CALCULATING DURATION
        duration= Number((duration).toFixed(2)); //ROUNDING OFF THE RESULT
        duration = duration<0?24+duration:duration;  // THIS MAKES SURE THAT THE DURATION IS ALWAYS POSITIVE IN CASE STARTIS GREATER THAN END TIME
        return duration; 
    }

    static notifications = (data) =>{
      swal({
        icon: data.isError? "warning" : "success",
        text: data.message,
        timer : 3000
      });
    }

    static saveShift = async (data) =>{
        try {
            const res = await axios.post(this.extractToken().api + "shift/?token=" + this.extractToken().token, data);
            this.notifications({message:res.data.res, isError : false});
            return res.data.res;
        }
        catch (error) {
          this.errorHandler(error)
          return error;
        }
    }

    static copyAndPastWeekShitfs = async (data, period) =>{
        try {
            const res = await axios.post(`${this.extractToken().api}shift/copyweekshifts/?token=${this.extractToken().token}&start=${period.start}&end=${period.end}`, { weekToPaste: data });
            this.notifications({message:res.data.res, isError : false});
            return res.data.res;
        }
        catch (error) {
          this.errorHandler(error)
          return error;
        }
    }

    static pasteShift = async (data) =>{
        try {
            const res = await axios.post(this.extractToken().api + "shift/paste/?token=" + this.extractToken().token, data);
            this.notifications({message:res.data.res, isError : false});
            return res.data.res;
        }
        catch (error) {
          this.errorHandler(error)
          return error;
        }
    }

    static transferShift = async (data) =>{ 
        try {
            const res = await axios.post(this.extractToken().api + "shift/transfer/?token=" + this.extractToken().token, data);
            this.notifications({message:res.data.res, isError : false});
            return res.data.res;
        }
        catch (error) {
          this.errorHandler(error) 
          return error;
        }
    }

    static acceptOpenShifts = async (id) =>{
        try {
            const res = await axios.get(this.extractToken().api + "shift/openshifts/accept?token=" + this.extractToken().token+"&id="+id);
            this.notifications({message:res.data.res, isError : false});
            return res.data.res;
        }
        catch (error) {
            this.errorHandler(error)
            return error;
        }
    }

    static approveShiftTransfer = async (id) =>{ 
      try {
          const res = await axios.get(this.extractToken().api + "shift/transfer/accept?token=" + this.extractToken().token+"&transferid="+id);
          this.notifications({message:res.data.res, isError : false});
          return res.data.res;
      }
      catch (error) {
        this.errorHandler(error)
        return error;
      }
  }

  static declineShiftTransfer = async (id) =>{ 
    try {
        const res = await axios.get(this.extractToken().api + "shift/transfer/decline?token=" + this.extractToken().token+"&transferid="+id);
        this.notifications({message:res.data.res, isError : false});
        return res.data.res;
    }
    catch (error) {
      this.errorHandler(error)
      return error;
    }
}


    static deleteShift = async (data) =>{
        try {
            const res = await axios.post(this.extractToken().api + "shift/delete?token=" + this.extractToken().token, data);
            this.notifications({message:res.data.res, isError : false});
            return res.data.res;
        }
        catch (error) {
          this.errorHandler(error)
          return error;
        }
    }
    static approveShift = async (data) =>{
        try {
            const res = await axios.post(this.extractToken().api + "shift/approve?token=" + this.extractToken().token, data);
            this.notifications({message:res.data.res, isError : false});
            return res.data.res;
        }
        catch (error) {
          this.errorHandler(error)
          return error;
        }
    }
    static confirmShift = async (data) =>{
        try {
            const res = await axios.post(this.extractToken().api + "shift/confirm?token=" + this.extractToken().token, data);
            this.notifications({message:res.data.res, isError : false});
            return res.data.res;
        }
        catch (error) {
          this.errorHandler(error)
          return error;
        }
    }
    static updateShift = async (data) =>{
        try {
            const res = await axios.post(this.extractToken().api + "shift/update?token=" + this.extractToken().token, data);
            this.notifications({message:res.data.res, isError : false});
            return res.data.res;
        }
        catch (error) {
          this.errorHandler(error)
          return error;
        }
    }
    static saveDraggedShifts = async (data) =>{
        try {
            const res = await axios.post(this.extractToken().api + "shift/dragge?token=" + this.extractToken().token, { data: data.draggeposition, ids: data.draggedshifts });
            this.notifications({message:res.data.res, isError : false});
            return res.data.res;
        }
        catch (error) {
          this.errorHandler(error)
          return error;
        }
    }
    static getParticipants = async () =>{
        try {
            const res = await axios.get(this.extractToken().api + "shift/participants?token=" + this.extractToken().token);
            return res.data.res;
        }
        catch (error) {
          this.errorHandler(error)
          return error;
        } 
    }
    static getParticipantServices = async (id) =>{
        try {
          console.log(id)
            const res = await axios.get(this.extractToken().api + "shift/services?clientid=" + id + "&token=" + this.extractToken().token);
            return res.data.res;
        }
        catch (error) {
          this.errorHandler(error)
          return error;
        }
    }

    static getParticpantShifts = async (data) => {
        try {
            const res = await axios.get(this.extractToken().api + "shift/?start=" + data.start + "&end=" + data.end + "&token=" + this.extractToken().token);
            return res.data.res;
        }
        catch (error) {
          this.errorHandler(error)
          return [];
        }
    }

    static getShiftsByStaff = async (data) => {
        try {
            const res = await axios.get(this.extractToken().api + "shift/view2/?start=" + data.start + "&end=" + data.end + "&token=" + this.extractToken().token);
            return res.data.res;
        }
        catch (error) {
          this.errorHandler(error)
          return [];
        }
    }

    static getStaffShifts = async (data) => {
        try {
            const res = await axios.get(this.extractToken().api + `shift/staff/?start=` + data.start + "&end=" + data.end + "&token=" + this.extractToken().token);
            return res.data.res;
        }
        catch (error) {
          this.errorHandler(error)
          return [];
        }
    }
    static getWorkers = async () => {
        try {
            const res = await axios.get(this.extractToken().api + "users/?token=" + this.extractToken().token);
            return res.data.res;
        }
        catch (error) {
          this.errorHandler(error)
          return [];
        }
    }
}