import React from 'react';
import moment from "moment"
import '../../assets/timesheetTable.css';
import RosterController from "../../controllers/roster";
import EditTimeSheet from '../modals/editTimeSheet';


export default class TimeSheetTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timesheets : props.timesheets,
            selectedTimeSheetIds : [],
            showEditTimeSheet : false
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.timesheets !== this.props.timesheets) {
            this.setState({timesheets : this.props.timesheets, selectedTimeSheetIds : []})
          }
        if (prevProps.selectedEmployee !== this.props.selectedEmployee) {
            this.setState({selectedEmployee : this.props.selectedEmployee})
          }
      }

    toggleEditTimeSheetModal = () =>{
        this.setState({showEditTimeSheet : !this.state.showEditTimeSheet})
    }
     
    render() {
        return (
            <>
                <table className = " timeshee-table ">
                    <thead style = {{"borderBottom":"solid #000000"}}>
                        <td style = {{width : "2%"}}>
                            <input  type ="checkbox" className = "m-2"
                                onClick = {() => {
                                    if(this.state.selectedTimeSheetIds.length==0){
                                        var timesheetsIds = [];
                                        this.state.timesheets.forEach(element => {
                                            timesheetsIds.push(element.id);
                                        });
                                        this.setState({selectedTimeSheetIds : timesheetsIds});
                                    }else{
                                        this.setState({selectedTimeSheetIds : []});
                                    }
                                }}
                                checked = {this.state.selectedTimeSheetIds.length!=0 && this.state.selectedTimeSheetIds.length == this.state.timesheets.length}
                            />
                        </td>
                        <th style = {{width : "18%"}}>Date</th>
                        <th style = {{width : "10%"}}>Start</th>
                        <th style = {{width : "10%"}}>End</th>
                        <th style = {{width : "10%"}}>Hours</th>
                        <th style = {{width : "16%"}}>Shift Status</th>
                        <th style = {{width : "16%"}}>Processing</th>
                        <th style = {{width : "10%"}}>Actions</th>
                    </thead>
                    <tbody>
                        {
                            this.state.timesheets.map(item =>
                                <tr
                                    style = {{cursor : "pointer", height: "10px", backgroundColor:this.state.selectedTimeSheetIds.includes(item.id)?"#adb8bb":""}}
                                    className = "timeshee-table-row"
                                    onClick = {(event)=>{
                                        var timesheetsIds = this.state.selectedTimeSheetIds;
                                        var index = this.state.selectedTimeSheetIds.findIndex(id=>id == item.id);
                                        if(index>=0){
                                            timesheetsIds = timesheetsIds.slice(0, index).concat(timesheetsIds.slice(index + 1, timesheetsIds.length));
                                        }else{
                                            timesheetsIds.push(item.id);
                                        }
                                        this.setState({selectedTimeSheetIds : timesheetsIds});
                                    }}
                                >
                                    <td><input value = {item.id} type ="checkbox" className = "m-2" checked = {this.state.selectedTimeSheetIds.includes(item.id)}/></td>
                                    <td><small>{moment(item.date).format("ddd Do MMM YYYY")}</small></td>
                                    <td><small>{item.starttime.split(":")[0]}:{item.starttime.split(":")[1]}</small></td>
                                    <td><small>{item.endtime.split(":")[0]}:{item.endtime.split(":")[1]}</small></td>
                                    <td><small>{RosterController.calculateDurationOfShift({starttime: item.starttime, endtime: item.endtime})}</small></td>
                                    <td>
                                        {
                                            !item.approved && !item.completed && !item.confirmed?
                                            <small>not attanded</small>
                                            :
                                            item.approved && !item.completed && !item.confirmed?
                                            <small>Approved</small>
                                            :
                                            item.approved && item.completed && !item.confirmed?
                                            <small>completed</small>
                                            :
                                            <small>confirmed</small>
                                        }
                                    </td>
                                    <td>
                                        {
                                            item.processed?
                                            <div className ="badge  bg-warning">pending</div>
                                            :
                                            <div className ="badge  bg-warning">pending</div>
                                        }
                                    </td>
                                    <td>
                                        <div onClick = {(event)=>{event.stopPropagation(); console.log(item); this.setState({shift : item}); this.toggleEditTimeSheetModal(); console.log(item);this.props.setSelectedShift(item)}} type = "button" className ="badge  bg-secondary m-1 mb-0 mt-0"><i class="bi bi-pencil-square"></i> Edit</div>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
                <EditTimeSheet selectedEmployee={this.state?.selectedEmployee} show = {this.state.showEditTimeSheet} data = {this.state?.shift}  modalFuction = {() => this.toggleEditTimeSheetModal()} />
            </>
        )
      }
}