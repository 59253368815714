import React, {useState} from 'react';
import '../assets/dashboard.css'
import WeekShiftCard from "../components/subcomponents/staffWeekShiftCard";
import ToolsController from '../controllers/tools';
import RosterController from '../controllers/roster';
import DashboardController from '../controllers/dashboard'
import Settings from './modals/settings';
import moment from 'moment';
import TransferModal from '../components/modals/trasferShift';
import ShiftKeyComponet from "./subcomponents/shiftColorCodeKey";
import {  Dropdown } from 'react-bootstrap';
import Notifications from './subcomponents/notification';
import Graph from '../components/subcomponents/echarts/dashboardGraph'


export default class DashboardView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profile : {
                id : null,
                firstname : "",
                lastname : "",
                email : "",
                shifts : [],
                showSettings : true,
                showTrasferShift : true,
                company: false
            },
            openShifts : [],
            transferedShifts :  [],
            weekstart: ToolsController.calculateWeekRange().start ,
            weekend: ToolsController.calculateWeekRange().end,
            staff : []
        }
    }

    toggleSettingModal = () =>{
        this.setState({showSettings : !this.state.showSettings})
    }

    toggleshowTrasferShiftModal = () =>{
        this.setState({showTrasferShift : !this.state.showTrasferShift});
    }

    getUserDetails =  ()    =>{
        RosterController.getStaffShifts({start: this.state.weekstart, end: this.state.weekend}).then(res=>{
            this.setState({profile: res})
        }).catch(error=>{
            this.setState({shifts: []});
        })

        DashboardController.getTransferedShifts().then(res=>{
            this.setState({transferedShifts : res})
        })

        DashboardController.getOpenShifts({start: this.state.weekstart, end: this.state.weekend}).then(res=>{
            this.setState({openShifts : res})
        })
    }
    
    componentDidMount () {
        DashboardController.getTransferedShifts().then(res=>{
            this.setState({transferedShifts : res})
        })

        DashboardController.getOpenShifts({start: this.state.weekstart, end: this.state.weekend}).then(res=>{
            console.log(res)
            this.setState({openShifts : res})
        })

        RosterController.getWorkers().then(res=>{
            var data = []
            res.forEach(element => {
                data.push({
                    label : element.name,
                    value : element.id
                })
            });
            this.setState({staff: data})
        }).catch(error=>{
            this.setState({staff: []});
        })
        this.getUserDetails();
        
    }

    TopBar = () =>{
        return (
            <div style={{float:"right"}}>
                <div style={{float:"right"}} className="tool-button col btn-group">
                    <button onClick={()=>{this.toggleshowTrasferShiftModal()}} type="button" className=" btn btn-outline-primary btn-sm m-1 mt-0 mb-0"><i class="bi bi-arrow-left-right"></i></button>
                        <Dropdown style = {{padding:"0px"}} className = "btn btn-sm border-primary m-1 mb-0 mt-0">
                            <Dropdown.Toggle  variant="" className="text-primary p-0 position-relative m-2 mb-0 mt-1"  id="dropdown-basic">
                                <i class="bi bi-bell p-0 text-primary position-relative">
                                    <span  class="position-absolute top-0 start-0 translate-middle p-2 bg-danger border border-light rounded-circle">
                                        <small className = " text-white position-absolute" style={{fontWeight : "bolder",marginLeft:"-4px",marginTop: "-8px",fontSize:"12px"}}>
                                            {this.state.openShifts.length + this.state.transferedShifts.length}
                                        </small>
                                    </span>
                                </i>
                                <small></small>
                            </Dropdown.Toggle>
                            <Notifications getUserDetails = {this.getUserDetails} openShifts = {this.state.openShifts} transferedShifts = {this.state.transferedShifts} />
                        </Dropdown>
                    <button onClick={()=>{this.toggleSettingModal()}} type="button" className=" btn btn-outline-primary btn-sm m-1 mt-0 mb-0"><i class="bi bi-gear"></i> </button>
                    <button onClick={()=>{
                            const dates= ToolsController.getPreviousWeek({start:this.state.weekstart});
                            this.setState({weekstart: dates.start, weekend: dates.end});
                            RosterController.getStaffShifts({start: dates.start, end: dates.end}).then(res=>{
                                this.setState({profile: res})
                            }).catch(error=>{
                                //this.setState({shifts: []});
                            })
                        }} type="button" className="btn btn-sm btn-outline-secondary">&#10094;</button>
                    <button type="button" className="btn btn-sm btn-outline-secondary ">
                        <span data-feather="calendar"></span>{moment(this.state.weekstart).format('Do MMM')} - {moment(this.state.weekend).format('Do MMM')}
                    </button>
                    <button onClick={()=>{
                            const dates = ToolsController.getNextWeek({end: this.state.weekend});
                            this.setState({weekstart: dates.start, weekend: dates.end})
                            RosterController.getStaffShifts({start: dates.start, end: dates.end}).then(res=>{
                                this.setState({profile: res})
                            }).catch(error=>{
                                //this.setState({shifts: []});
                            })
                        }}  type="button" className="btn btn-sm btn-outline-secondary">&#10095;</button>
                </div>
            </div>
        )
    }
 
    render() { 
        return (
            <div> 
                <div  className="row mt-3 mb-2 top-bar">
                    {/* <div  className = "col "><div id="notifications"></div></div> */}
                    <this.TopBar></this.TopBar>
                </div>

                <div className=' d-flex justify-content-center'>
                    <div  className="mt-4 content-page">
                        {/* <div className="mb-sm-4">
                            <StaffProfile profile = {this.state.profile} />
                        </div> */}
                        <div>
                            <WeekShiftCard shifts = {this.state.profile.shifts} getUserDetails = {()=>this.getUserDetails()} weekstart={this.state.weekstart} weekend = {this.state.weekend}/>
                        </div>
                        <div style={{width : "100%"}} className='shadow-lg  bg-transparent mt-4'>
                            <Graph timesheet = {this.state.profile.timesheet}/>
                        </div>
                    </div>
                </div>
                <div className = "mb-4">
                    {/* <ShiftKeyComponet></ShiftKeyComponet> */}
                </div>
                <TransferModal 
                getUserDetails = {() => this.getUserDetails()} 
                shifts = {()=>{
                    var data = [];
                    this.state.profile.shifts.forEach(element => {
                        data.push({
                            label : `${moment(element.date).format('ddd')} ${moment(element.date).format('DD')} ${moment(element.date).format('MMM')} from ${element.starttime} ${element.endtime}`,
                            value : element.id
                        })
                    });
                    return data;
                }} staff = {this.state.staff} show = {this.state.showTrasferShift} modalFuction = {() => this.toggleshowTrasferShiftModal()} />
                <Settings getUserDetails = {() => this.getUserDetails()}  show = {this.state.showSettings} data = {this.state.profile} modalFuction = {() => this.toggleSettingModal()} />
            </div>
        )
      }
}