import React from 'react';
import '../../assets/timesheetTable.css';
import RosterController from "../../controllers/roster";

export default class TimeSheetTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timesheets : props.timesheets,
            selectedTimeSheetIds : []
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.timesheets !== this.props.timesheets) {
            this.setState({timesheets : this.props.timesheets, selectedTimeSheetIds : []})
          }
      }
    
      
    render() {
        return (
            <>
                <table className = " timeshee-table ">
                    <thead style = {{"borderBottom":"solid #000000"}}>
                        <td style = {{width : "2%"}}>
                            <input  type ="checkbox" className = "m-2"
                                onClick = {() => {
                                    if(this.state.selectedTimeSheetIds.length==0){
                                        var timesheetsIds = [];
                                        this.state.timesheets.forEach(element => {
                                            timesheetsIds.push(element.id);
                                        });
                                        this.setState({selectedTimeSheetIds : timesheetsIds});
                                    }else{
                                        this.setState({selectedTimeSheetIds : []});
                                    }
                                }}
                                checked = {this.state.selectedTimeSheetIds.length!=0 && this.state.selectedTimeSheetIds.length == this.state.timesheets.length}
                            />
                        </td>
                        <th style = {{width : "10%"}}>Staff</th>
                        <th style = {{width : "6%"}}>Start</th>
                        <th style = {{width : "6%"}}>End</th>                        
                        <th style = {{width : "41%"}}>Service(s)</th>
                        <th style = {{width : "7%"}}>Price</th>
                        <th style = {{width : "8%"}}>Duration</th>
                        <th style = {{width : "10%"}}>Shift Status</th>
                        <th style = {{width : "5%"}}>Processing</th>
                    </thead>
                    <tbody>
                        {/* staff, duration, */}
                        {
                            this.state.timesheets.map(item =>
                                <tr
                                    style = {{cursor : "pointer", height: "10px", backgroundColor:this.state.selectedTimeSheetIds.includes(item.id)?"#adb8bb":""}}
                                    className = "timeshee-table-row"
                                    onClick = {(event)=>{
                                        var timesheetsIds = this.state.selectedTimeSheetIds;
                                        var index = this.state.selectedTimeSheetIds.findIndex(id=>id == item.id);
                                        if(index>=0){
                                            timesheetsIds = timesheetsIds.slice(0, index).concat(timesheetsIds.slice(index + 1, timesheetsIds.length));
                                        }else{
                                            timesheetsIds.push(item.id);
                                        }
                                        this.setState({selectedTimeSheetIds : timesheetsIds});
                                    }}
                                >
                                    <td><input value = {item.id} type ="checkbox" className = "m-2" checked = {this.state.selectedTimeSheetIds.includes(item.id)}/></td>
                                    <td>
                                        <small>
                                            {
                                                item.tbluser?
                                                `${item.tbluser.firstname} ${item.tbluser.lastname}`
                                                :
                                                `No Assigned Staff`
                                            }
                                        </small>
                                    </td>
                                    <td><small>{item.starttime}</small></td>
                                    <td><small>{item.endtime}</small></td>
                                    <td><small>{item.tbl_support_service.support_service}</small></td>
                                    <td><small>{item.costperhour}</small></td>
                                    <td><small>{RosterController.calculateDurationOfShift({starttime: item.starttime, endtime: item.endtime})}</small></td>
                                    
                                    {/* <td><small>{RosterController.calculateDurationOfShift({starttime: item.starttime, endtime: item.endtime})}</small></td> */}
                                    <td>
                                        {
                                            !item.approved && !item.completed && !item.confirmed?
                                            <small>Unattended</small>
                                            :
                                            item.approved && !item.completed && !item.confirmed?
                                            <small>Approved</small>
                                            :
                                            item.approved && item.completed && !item.confirmed?
                                            <small>Completed</small>
                                            :
                                            <small>Confirmed</small>
                                        }
                                    </td>
                                    <td>
                                        {
                                            item.processed?
                                            <div className ="badge  bg-warning">pending</div>
                                            :
                                            <div className ="badge  bg-warning">pending</div>
                                        }
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </>
        )
      }
}