import React from 'react'
import axios from "axios"
import RosterController from '../controllers/roster';

export default class Controller extends React.Component {
    static getSILDetails = async (id) => {
        try {
            const res = await axios.get(RosterController.extractToken().api + "shift/sil/details?token=" + RosterController.extractToken().token + "&clientid=" + id);
            return res.data.res;
        }
        catch (error) {
            if (error.request) {
                return JSON.parse(error.request.response).res;
            }
            else {
                return error.message;
            }
        }
    }

    static generateSILShiftFromParticipantPlan = async (id) => {
        try {
            const res = await axios.get(RosterController.extractToken().api + "shift/sil/create?token=" + RosterController.extractToken().token + "&clientid=" + id);
            return res.data.res;
        }
        catch (error) {
            if (error.request) {
                return JSON.parse(error.request.response).res;
            }
            else {
                return error;
            }
        }
    }
}