
import React from 'react';

 const ShiftKeyComponet = (props) => {
    return (
        <div style = {{backgroundColor : "#dfe7e9", width : "100%"}}  className = "position-absolute bottom-0 end-0">
            <div  className = "row mt-1">
                <div className = "col">
                    <div className ="row">
                        <small className = "col-12 m-4 mt-0 mb-0"><div className = "mb-2 small"><b>Shift Color Codes Key</b></div></small>
                    </div>
                </div>
                <div className = "col">
                    <div className ="row">
                        <small className ="col-1"><div style = {{borderRadius : "50%", backgroundColor : "#8b9197", height : "13px", width : "13px"}}></div></small> 
                        <small className = "col-9"><div className = "mb-2 small"><b>Unapproved</b></div></small>
                    </div>
                </div>
                <div className = "col">
                    <div className ="row">
                        <small className ="col-1"><div style = {{borderRadius : "50%", backgroundColor : "#df8585", height : "13px", width : "13px"}}></div></small> 
                        <small className = "col-9"><div className = "mb-2 small"><b>Unattended</b></div></small>
                    </div>
                </div>
                <div className = "col">
                    <div className ="row">
                        <small className ="col-1"><div style = {{borderRadius : "50%", backgroundColor : "green", height : "13px", width : "13px"}}></div></small> 
                        <small className = "col-9"><div className = "mb-2 small"><b>Approved</b></div></small>
                    </div>
                </div>
                <div className = "col">
                    <div className ="row">
                        <div className ="col-1"><div style = {{borderRadius : "50%", backgroundColor : "blue", height : "13px", width : "13px"}}></div></div> 
                        <small className = "col-9 small"><b>Completed</b></small>
                    </div>
                </div>
                <div className = "col">
                    <div className ="row">
                        <div className ="col-1"><div style = {{borderRadius : "50%" , backgroundColor : "#f4c842", height : "13px", width : "13px"}}></div></div> 
                        <small className = "col-9 small"><b>Confirmed</b></small>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default ShiftKeyComponet;