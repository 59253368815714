import React, { useState } from 'react';
import ReactECharts from 'echarts-for-react';

export default class Series extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            count:0,
            setCount:0
        }
    }
	render(){
		const option = {
            title : {
              text: 'WEEK DAILY REVENUES',
              subtext: 'Participant Services',
              x:'center'
            },
            tooltip : {
              trigger: 'item',
              formatter: "{a} <br/>{b} : {c} ({d}%)"
            },
            legend: {
              orient: 'vertical',
              left: 'left',
              data: ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday']
            },
            series : [
              {
              name: 'Revenues',
              type: 'pie',
              radius : '55%',
              center: ['50%', '60%'],
              data:this.props.data,
              itemStyle: {
                emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
              }
            ]
          };
        
        //   const [count, setCount] = useState(0);
		  function onChartReady(echarts) {
            console.log('echarts is ready', echarts);
          }
        
          function onChartClick(param, echarts) {
            console.log(param, echarts);
            // setCount(count + 1);
          };
        
          function onChartLegendselectchanged(param, echarts) {
            console.log(param, echarts);
          };
        
          return (
            <>
              <ReactECharts
                option={option}
                style={{ height: 400 }}
                onChartReady={onChartReady}
                onEvents={{
                  'click': onChartClick,
                  'legendselectchanged': onChartLegendselectchanged
                 }}
              />
            </>
          );        
	}
}

