import React from "react"
import '../../assets/settings.css';
import '../../assets/roster.css';
import momenttimezone from "moment-timezone";
import { Modal} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead'; 
import 'react-bootstrap-typeahead/css/Typeahead.css';
import SettingsController from '../../controllers/settings';

export default class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            show:  props.show,
            timezone : momenttimezone.tz.guess(),
            timezones :[],
            data : props.data,
            connectedToXero : false
        }
        
    }

    componentDidMount(){
        momenttimezone.tz.names().forEach(element=>{
            this.state.timezones.push({
                value : element,
                label : element
            })
        })
    }

    saveTimeZone = () =>{
        SettingsController.saveTimeZone({
            name : 'time',
            value : {
                timezone : this.state.timezone
            }
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show) {
            this.setState({show: this.props.show})
          }
        if (prevProps.data !== this.props.data) {
            this.setState({data: this.props.data, connectToXero : this.props.data.connectToXero});
          }
      }
      
    render(){
        return <Modal  size = "l" className="fade" show={this.state.show} onHide={()=>{this.props.modalFuction()}} >
                    <Modal.Body>
                        <button type="button" className="close btn position-absolute end-0 top-0" onClick={()=>{this.props.modalFuction()}}>
                            &times;
                        </button>
                        <center className="start-50 m-2 h3">System Settings</center>
                        <hr></hr>
                        <div className="mt-3 mb-2">
                            <div className="mt-2 mb-2">
                                <center className = "h4">My Time Zone</center>
                            </div>
                            <div className = "row">
                                <div className="col-3">Time Zone </div>
                                <div className = "col-7">
                                    <Typeahead
                                        id="custom-selections-example"
                                        newSelectionPrefix="Participant: "
                                        options={this.state.timezones}
                                        defaultInputValue = {momenttimezone.tz.guess()}
                                        onChange={(event)=>{
                                            if(event[0]){
                                                this.setState({timezone : event[0].value});
                                            }
                                        }}
                                        placeholder="Seleact Your Time Zone..."
                                    />
                                </div>
                                <div className="col-1">
                                    <button className="btn-primary btn btn-small" onClick={()=>{this.saveTimeZone()}}>Save</button>
                                </div>
                                
                            </div>
                            <div className="mt-3">
                                <center className = "h4">Xero Settings</center>
                            </div>
                            <div className="row m-2">
                                <div className="col-9">Connect My Roster To Xero</div>
                                <div className="col-3">
                                    <label class="switch">
                                        <input defaultChecked = {this.state.connectedToXero} 
                                        onChange = {(event)=>{
                                            if (event.target.checked) {
                                                SettingsController.connectToXero().then(res =>{
                                                    this.setState({connectToXero:true})
                                                }).catch(error=>{
                                                    this.setState({connectToXero:false})
                                                })
                                            }else{
                                                SettingsController.disConnectToXero().then(res =>{
                                                    this.setState({connectToXero:false})
                                                }).catch(error=>{
                                                    this.setState({connectToXero:true})
                                                });
                                            }
                                        }}type="checkbox"/>
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="row m-2">
                                <div className="col-9">Sychronise Employee Data with Xero</div>
                                <div className="col-3">
                                    <button onClick = {()=>SettingsController.syncEmpoyeeDataWithXero().then(res=>this.props.getUserDetails())} className = "btn btn-sm btn-outline-primary">Sychronise</button>
                                </div>
                            </div>
                            <div className="row m-2">
                                <div className="col-9">Sychronise Client Contacts with Xero</div>
                                <div className="col-3">
                                    <button  onClick = {()=>SettingsController.syncCustomerDataWithXero().then(res => this.props.getUserDetails())} className = "btn btn-sm btn-outline-primary">Sychronise</button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
    }
}