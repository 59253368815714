import React from 'react';
import '../../assets/staffWeekShiftCard.css'
import DashboardController from '../../controllers/dashboard';

import moment from 'moment';

export default class DashboardView extends React.Component { 
    constructor(props) {
        super(props);
        this.state = {
            shifts: [],
            weekstart: props.weekstart,
            weekend: props.weekend
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.shifts !== this.props.shifts) {
            this.setState({shifts: this.props.shifts, weekstart : this.props.weekstart, weekend : this.props.weekend})
          }
      }
      
    render() {
        return (
            <>
                <div className="card shadow-lg  bg-transparent position-relative">
                    <div className="card-header fw-bolder ">
                        <div className="float-start">My Shifts For The Week</div> 
                        <div className="float-end text-muted"> 
                            {/* <small>{this.props.shifts.length} </small> 
                            <small className="text-danger">{this.props.shifts.filter(x=>moment(x.date).format('MM/DD/YYYY')<moment().format('MM/DD/YYYY')).length}</small>  
                            <small className="text-success">{this.props.shifts.filter(x=>x.approved&&!x.confirmed).length} </small> 
                            <small className="text-warning">{this.state.shifts.filter(x=>x.confirmed).length} </small>  */}
                            <small onClick={()=>this.props.getUserDetails()} class="bi badge text-dark btn btn-outline-primary bi-arrow-clockwise">Refresh</small>
                        </div>
                    </div>
                    <div className="card-body" style={{height:"500px", overflowX:"scroll"}}>
                        {
                            this.props.shifts.map((row)=>
                                <div className="shiftcard">
                                    <div className={
                                        row.approved? 
                                            row.confirmed? 
                                            "card bg-transparent  m-2 shift-border-confirmed position-relative "
                                                :
                                            "card  bg-transparent  m-2 shift-border-approved position-relative "
                                                :
                                            "card bg-transparent  m-2 shift-border-unapproved position-relative "
                                        }
                                    >
                                        { 
                                            row.completed && !row.confirmed?
                                                <div className="badge shift-completed  position-absolute top-0 end-0">Completed</div>
                                            :

                                            row.confirmed? 
                                                <div className="badge bg-warning  position-absolute top-0 end-0">Confirmed</div>
                                                    :
                                                <div>
                                                    {
                                                        moment(row.date).format('MM/DD/YYYY')<moment().format('MM/DD/YYYY')&&row.clockin=="null"?
                                                        <div className="badge btn-danger btn text-white  position-absolute top-0 end-0">Unattended</div>
                                                        :
                                                        <div>
                                                            {
                                                                row.clockin==null?
                                                                <div type="button" className="badge btn-outline-success btn text-dark  position-absolute top-0 end-0"
                                                                    onClick = {() =>{
                                                                        DashboardController.clockin(row.id).then(res=>{
                                                                            this.props.getUserDetails();
                                                                        })
                                                                    }}
                                                                >Start Shift</div>
                                                                :
                                                                <div type="button" className="badge btn-outline-danger btn text-dark position-absolute top-0 end-0"
                                                                    onClick = {() =>{
                                                                        DashboardController.clockout(row.id).then(res=>{
                                                                            this.props.getUserDetails();
                                                                        })
                                                                    }}
                                                                >Finish Shift</div>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                               
                                        }
                                        <div className="row">
                                            <div class="d-flex flex-row bd-highlight mb-3 ">
                                                <div class="p-2 bd-highlight shift-tittle"><i class="bi bi-calendar-event"></i> {`${moment(row.date).format('ddd Do MMM')} `} </div>
                                                <div class="pt-2 bd-highlight shift-tittle" ><i class="bi bi-alarm"> {`${row.starttime.split(":")[0]}:${row.starttime.split(":")[1]} - ${row.endtime.split(":")[0]}:${row.endtime.split(":")[1]}`}</i></div>
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <small className="m-1 mt-0 mb-0 text-muted "><i class="bi bi-geo-alt-fill"> {row.address}</i></small>
                                        </div>

                                        <div className="row">
                                            <small className="m-1 mt-0 mb-0 small "><i class="bi bi-journals" title="Shift Notes"></i> {row.notes?row.notes : "No notes added for to shift"}</small>
                                        </div>

                                        <div className="row">
                                            {
                                                row.tblparticipant?
                                                <div>{`${row.tblparticipant.lastname} ${row.tblparticipant.firstname}`} | <small className = "small">{row.tbl_support_service.support_service}</small></div>
                                                :
                                                ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                </div>
            </>
        )
      }
}