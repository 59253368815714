import React from 'react';
import Reports from '../components/reports'

export default class ReportsView extends React.Component {
    render() {
        return (
            <>
                <div style={{"height":"100%","marginRight":"1%", "marginLeft":"1%"}}>
                    <Reports/>
                </div>
            </>
        )
      }
}