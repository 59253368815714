import React from "react"
import moment from "moment";
import { Modal} from 'react-bootstrap';

import RosterController from '../../controllers/roster';

export default class WeekShiftCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      weekstart : props.weekstart,
      weekend   : props.weekend,
      showCopyWeekModal : props.showCopyWeekModal,
      getNextWeeks : [],
      copiedWeekShifts : []
    }
  }


  calculatePasteWeek = (weekend) => {
    var start = moment(weekend).format('MM/DD/YYYY');
    start = moment(start).add(1, 'days').format('MM/DD/YYYY');
    var end = moment(start).add(6, 'days').format('MM/DD/YYYY')
    var arr = [];
    for (let index = 0; index < 5; index++) {
      var end = moment(start).add(6, 'days').format('MM/DD/YYYY');
      arr.push({ weekstart: start, weekend: end });
      start = moment(end).add(1, 'days').format('MM/DD/YYYY');
    }
    this.setState({ getNextWeeks: arr });
  }


  componentDidMount(){
    this.calculatePasteWeek(this.state.weekend);
  }


  componentDidUpdate(prevProps) {
    if (prevProps.weekend !== this.props.weekend) {
        this.setState({weekstart : this.props.weekstart, weekend : this.props.weekend});
        this.calculatePasteWeek(this.props.weekend);
      }
    if(prevProps.showCopyWeekModal !== this.props.showCopyWeekModal){
      this.setState({showCopyWeekModal: this.props.showCopyWeekModal});
    }
  }

  render(){
    return (
      <>
        <Modal show={this.state.showCopyWeekModal} onHide={()=>{this.setState({copiedWeekShifts:[]});this.props.closeModal()}}  >
          <Modal.Header>
            <Modal.Title><h5 className="modal-title" >Copy shifts to</h5></Modal.Title>
            <button type="button" className="close btn" onClick={()=>{this.setState({copiedWeekShifts:[]});this.props.closeModal()}}>
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row m-2">

              <div className="col-md-12" style={{ height: "250px", overflowY: "scroll" }}>
                  <div class="form-group" >
                    {
                      this.state.getNextWeeks.map((element) =>
                        <div id={element.weekstart} onClick={() => {
                          var arr = [];
                          arr = this.state.copiedWeekShifts;
                          var index = arr.findIndex(x =>x.weekstart == element.weekstart);
                          
                          if (index >= 0) {
                            arr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));

                          } else {
                            arr.push({ weekstart: element.weekstart, weekend: element.weekend });
                          }
                          this.state.copiedWeekShifts=arr;
                          this.setState({ copiedWeekShifts: arr });
                          arr = [];
                        }} class="form-check border rounded mb-1 p-1">
                          <input class="form-check-input m-1" type="checkbox" id={element.weekstart} checked={this.state.copiedWeekShifts.findIndex(x => x.weekstart == element.weekstart)>-1} />
                          <label class="form-check-label ml-4" for="gridCheck">
                            {moment(element.weekstart).format("DD-MM-YYYY")} to {moment(element.weekend).format("DD-MM-YYYY")}
                          </label>
                        </div>
                      )
                    }
                  </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={()=>RosterController.copyAndPastWeekShitfs(this.state.copiedWeekShifts, {start : this.state.weekstart, end : this.state.weekend}).then(res=>RosterController.notifications({message : res}))}>Paste shifts</button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
    
}
