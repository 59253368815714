import React from 'react';
import moment from 'moment';
import '../assets/roster.css'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import RosterController from '../controllers/roster'
import Tools from './subcomponents/tools'
import ShiftModal from '../components/modals/addShiftModal'
import ShiftKeyComponet from "./subcomponents/shiftColorCodeKey";
import Swal from 'sweetalert2';


export default class Roster extends React.Component { 
    constructor(props) {
        super(props);
        this.state = {
            weekStart : "",
            weekEnd : "",
            showModal : false,
            participantServices : [],
            shifts:[],
            shifts_data:[],
            dayShifts:[],
            clients:[],
            nameSearched: "",
            selectedPerson:{id:0, name:"", address:""},
            draggedshifts:[],
            draggeposition:null,
            tabkey:"profile",
            copiedShift:{},
            workers:[],
            selectedParticipantShiftIDs:[],
            viewRosterByParticipants : false,
        }
        console.log(props.permissions)
    }

    componentDidUpdate(prevProps){
        if (prevProps.permissions != this.props.permissions){
            console.log(this.props.permissions)
        }
    }

    componentDidMount(){
        RosterController.getWorkers().then(res=>{
            var data =[];
            res.forEach(element=>{
                data.push({
                    value: element.id,
                    label: element.name
                })
            })
            this.setState({workers:data});
        })
    }

    bulkSelectItems = () =>{
        var data = [];
        this.state.selectedParticipantShiftIDs.length<1?
        this.state.shifts.forEach(element=>{
            element.shifts.forEach(item => {
                data.push(item.id);
            });
        })
        :
        data = []
        //UPDATING THE STATE
        this.setState({selectedParticipantShiftIDs: data});
    }
    searchParticipant = (name)=>{
        let data = this.state.shifts_data.filter(value => {
            return (value.name.toLowerCase().includes(name.toLowerCase()));
        });
        this.setState({shifts: data});
    }
   
    shiftModal= (data) =>{
        const clientid = this.state.viewRosterByParticipants?data.clientid : 0;
        RosterController.getParticipantServices(clientid).then(s=>{
            RosterController.getParticipants().then(c=>{
                var clients =[];
                c.forEach(element=>{
                    clients.push({value: element.id, label: element.name, address: element.address})
                })
                var services=[];
                s.forEach(element=>{
                    services.push({label: element.supportService,servicePrice: element.servicePrice, value: element.supportServiceId})
                })
                this.setState({showModal:true,tabkey: data.shifts.length>0?"profile":"home", dayShifts:data.shifts, participantServices: services,clients: clients, selectedPerson: {id :data.clientid, name : data.name, address: data.address}, selectedShiftDate: data.day});
            })
        }).catch(error=>{
            console.log(error);
        })
    }
    getParticipantServices = (clientid)=>{
        RosterController.getParticipantServices(clientid).then(s=>{
            var services=[];
            s.forEach(element=>{
                services.push({label: element.supportService, servicePrice: element.servicePrice, value: element.supportServiceId})
            })
            this.setState({participantServices:services});
        })
    }
    updateShiftState=(data)=>{
        if (this.state.viewRosterByParticipants ){
            return RosterController.getParticpantShifts(data).then(shifts=>{
                
                this.setState({shifts:shifts, shifts_data:shifts});
                return shifts;
            })
        }else{
            return RosterController.getShiftsByStaff(data).then(shifts=>{
                
                this.setState({shifts:shifts, shifts_data:shifts});
                return shifts;
            })
        }
        
    }
    updateWeekStates=(data)=>{
        this.setState({weekStart:data.start, weekEnd: data.end})
    }
   
    executeSaveShift =(data,event)=>{
        event.preventDefault();
        RosterController.saveShift(data).then(res=>{
            this.setState({selectedPerson:{id:0, name:"", address:""}})
            this.updateShiftState({start:this.state.weekStart, end:this.state.weekEnd, showModal:false}).then(shifts=>{
                if (this.state.nameSearched!="") {
                    this.searchParticipant(this.state.nameSearched);
                }else{
                    this.setState({shifts:shifts, shifts_data:shifts});
                }
                this.setState({showModal:false})
                
            });
        }).catch(error=>{
            //console.log(error);
        })
    }
    executePasteShift =(data)=>{ 
        const list=data.shifts;
        var pos=0;
        list.forEach(element=>{
            pos++;
            if(this.state.viewRosterByParticipants){
                element.clientid=data.clientid;
            }else{
                element.staffid=data.clientid;
            }
            element.date=data.day;
            if(pos==list.length){
                RosterController.pasteShift(list).then(res=>{
                    
                    this.updateShiftState({start:this.state.weekStart, end:this.state.weekEnd, showModal:false}).then(shifts=>{
                        if (this.state.nameSearched!="") {
                            this.searchParticipant(this.state.nameSearched);
                        }else{
                            this.setState({shifts:shifts, shifts_data:shifts});
                        }
                    });
                }).catch(error=>{
                    console.log(error);
                })
            }
        })
    }

    executeDeleteShift =(data)=>{
        Swal.mixin({customClass: {confirmButton: 'btn btn-success ml-2',cancelButton: 'btn btn-danger'},buttonsStyling: false}).fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
                RosterController.deleteShift(data).then(res=>{
                    var list =this.state.dayShifts;
                    data.forEach(element=>{
                        const index=list.findIndex(x=>x.id==element);
                        list = list.slice(0, index).concat(list.slice(index + 1, list.length));
                    });
                    this.updateShiftState({start:this.state.weekStart, end:this.state.weekEnd, showModal:false}).then(shifts=>{
                        
                        if (this.state.nameSearched!="") {
                            this.searchParticipant(this.state.nameSearched);
                        }else{
                            this.setState({shifts:shifts, shifts_data:shifts});
                        }
                    });
                    this.setState({dayShifts:list, selectedParticipantShiftIDs:[]})
                }).catch(error=>{
                    //console.log(error);
                })
            } 
          })

    }

    executeApproveShift =(data)=>{
        RosterController.approveShift(data).then(res=>{
            
            var list =this.state.dayShifts;
            data.forEach(element=>{
                const index=list.findIndex(x=>x.id==element);
                if(index>=0){
                    list[index].approved=true;
                }
            });
            this.updateShiftState({start:this.state.weekStart, end:this.state.weekEnd, showModal:false}).then(shifts=>{
                if (this.state.nameSearched!="") {
                    this.searchParticipant(this.state.nameSearched);
                }else{
                    this.setState({shifts:shifts, shifts_data:shifts});
                }
            });
            this.setState({dayShifts:list,selectedParticipantShiftIDs:[]})
        }).catch(error=>{
            // console.log(error);
        })
    }

    executeConfirmShift =(data)=>{
        RosterController.confirmShift(data).then(res=>{
            
            var list =this.state.dayShifts;
            data.forEach(element=>{
                const index=list.findIndex(x=>x.id==element);
                if(index>=0){
                    list[index].confirmed=true
                }
            });
            this.updateShiftState({start:this.state.weekStart, end:this.state.weekEnd, showModal:false}).then(shifts=>{
                if (this.state.nameSearched!="") {
                    this.searchParticipant(this.state.nameSearched);
                }else{
                    this.setState({shifts:shifts, shifts_data:shifts});
                }
            });
            this.setState({dayShifts:list, selectedParticipantShiftIDs:[]})
        }).catch(error=>{
            //console.log(error);
        })
    }
    
    updateParticipantShifts = (data) =>{
        var list=[];
        data.forEach(element=>{
            list.push({
                id: element.id, 
                serviceid : element.serviceid,
                starttime: element.starttime,
                endtime : element.endtime,
                date : element.date,
                staffid: element.staffid,
                costperhour : element.costperhour
            });
        })
        return RosterController.updateShift(list).then(res=>{
            
            // this.closeModel();
            this.updateShiftState({start:this.state.weekStart, end:this.state.weekEnd, showModal:true}).then(shifts=>{
                var personalShift =[];
                shifts[shifts.findIndex(x=>x.clientid==this.state.selectedPerson.id)].shifts.forEach(element=>{
                    if (moment(element.date).format('dddd')==moment(this.state.selectedShiftDate).format('dddd')) personalShift.push(element);
                })
                this.setState({dayShifts: personalShift});
                if (this.state.nameSearched!="") {
                    this.searchParticipant(this.state.nameSearched);
                }else{
                    this.setState({shifts:shifts, shifts_data:shifts});
                }
            });
        }).catch(error=>{
            console.log(error)
        })
    }
    onDrageStart=(event)=>{
        event.forEach(element=>{
            this.state.draggedshifts.push(element.id)
        })
    }
    allowDrop = (ev) =>{
        ev.preventDefault();
      }
      
    drag = (ev) =>{
        ev.dataTransfer.setData("text", ev.target.id);
      }
      
    drop = (ev) =>{
        ev.preventDefault();
        var data = ev.dataTransfer.getData("text");
        ev.target.appendChild(document.getElementById(data));
      }
   
    onDragEnter = (event) =>{
        if(this.state.viewRosterByParticipants){
            this.setState({draggeposition:{clientid:event.clientid, date:event.day}});
        }else{
            this.setState({draggeposition:{staffid:event.clientid, date:event.day}});
        }
    }
    onDragEnded =()=>{
        RosterController.saveDraggedShifts({draggedshifts:this.state.draggedshifts,draggeposition:this.state.draggeposition}).then(res=>{
            this.updateShiftState({start:this.state.weekStart, end:this.state.weekEnd, showModal:false}).then(shifts=>{
                this.setState({shifts:shifts});
            });
        }).catch(error=>{
            console.log(error)
        })
        this.setState({draggedshifts:[]})
    }
    closeModel = () =>{
        this.setState({showModal:false});
    }
    shiftsDisplaying = (data) =>{
        var dayShifts=[];
        data.shifts.forEach(element => {
            if(moment(element.date).format('dddd')==moment(data.day).format('dddd')){
                dayShifts.push(element);
            }
        });
        
        const renderTooltip = (message) => (
            <Tooltip id="button-tooltip" >
              {message}
            </Tooltip>
          );
        return <div>
            {
                dayShifts.length!=0?
                <div>
                    <div 
                    style={{"width":"100%", "overflowX":"hidden", "overflowY":"hidden"}}
                    onDragStart={(event)=>{this.drag(event);this.onDrageStart(dayShifts)}}  draggable="true"
                    onDoubleClick={() => {this.shiftModal({shifts: dayShifts,clientid:data.clientid, name: data.name, address:data.address, day : data.day})}}
                    onClick={()=>{
                        var list = this.state.selectedParticipantShiftIDs;
                        dayShifts.forEach(element=>{
                            const index=list.findIndex(x=>x==element.id);
                            if(index<0){
                                list.push(element.id);
                            }else{
                                list = list.slice(0, index).concat(list.slice(index + 1, list.length));
                            }
                        })
                        this.setState({selectedParticipantShiftIDs:list});
                    }}
                    type="button"
                    className={
                            !dayShifts[0].approved?
                            "shift-unapproved cell shifts border-secondary form-control  position-relative text-wrap m-0" 
                            :
                            dayShifts[0].approved&&!dayShifts[0].completed?
                            "shift-approved cell shifts  border-success   form-control  position-relative text-wrap m-0"
                            :
                            dayShifts[0].completed&&!dayShifts[0].confirmed?
                            "shift-completed cell shifts shift-border-completed form-control  position-relative text-wrap m-0"
                            :
                            dayShifts[0].confirmed?
                            "shift-confirmed cell shifts border-primary form-control position-relative text-wrap m-0"
                            :
                            ""
                        }
                    >
                        <div type="button"  className="position-relative ">
                            <center className="text-wrap " style={{"width":"100%"}} >
                                <div className={!dayShifts[0].approve?"text text-dark":dayShifts[0].approve&&!dayShifts[0].confirm?"text text-success":"text text-primary"}>
                                    <b>{dayShifts[0].starttime.split(":")[0]+":"+dayShifts[0].starttime.split(":")[1]}-{dayShifts[0].endtime.split(":")[0]+":"+dayShifts[0].endtime.split(":")[1]}</b><br></br> 
                                    <small>{dayShifts[0].tbl_support_service?dayShifts[0].tbl_support_service.support_service: `A Shift @ ${data.address}`} </small>
                                </div>
                            </center>
                        </div>
                        <span style={{"height":"100%","width":"100%"}} className="position-absolute top-0 end-10 badge rounded-pill actions row">
                            <div type="button" className=" badge text-white" onClick={(event) => {event.stopPropagation();this.shiftModal({shifts: dayShifts,clientid:data.clientid, name: data.name, address:data.address, day : data.day})}} ><div className="large-screen">Open</div><div className="small-screen"><i class="bi bi-eye-fill"></i></div></div>
                            <div type="button" className=" badge text-white " onClick={(event)=>{
                                    event.stopPropagation();
                                    this.state.copiedShift=dayShifts;
                                    RosterController.notifications({message:'Shift(s) coppied to clipboard', isError : false})
                                }} ><div className="large-screen">Copy</div><div className="small-screen"><i class="bi bi-clipboard-check bold"></i></div></div>
                            <div type="button" className=" badge text-white " onClick={(event) => {event.stopPropagation();this.executePasteShift({shifts:this.state.copiedShift, clientid:data.clientid, day: data.day})}} ><div className="large-screen">Paste</div><div className="small-screen"><i class="bi bi-clipboard-plus"></i></div></div>
                        </span>
                        <div className="position-absolute top-0 start-0">
                            {
                                this.state.selectedParticipantShiftIDs.length>0?
                                <input onClick={()=>{
                                    var list = this.state.selectedParticipantShiftIDs;
                                    dayShifts.forEach(element=>{
                                        const index=list.findIndex(x=>x==element.id);
                                        if(index<0){
                                            list.push(element.id);
                                        }else{
                                            list = list.slice(0, index).concat(list.slice(index + 1, list.length));
                                        }
                                    })
                                    this.setState({selectedParticipantShiftIDs:list});
                                }} type="checkbox" checked={this.state.selectedParticipantShiftIDs.includes(dayShifts[0].id)}/>
                                :
                                ''
                            }
                        </div>
                        {dayShifts.length>1?
                            <span  className="position-absolute bottom-0 end-0 badge rounded-pill row">
                                <div type="button" class=" badge tag" onClick={() => {this.shiftModal({shifts: dayShifts,clientid:data.clientid, name: data.name, address:data.address, day : data.day})}}><small>{dayShifts.length-1} More </small></div>
                            </span>
                            :
                            ""
                        }
                        
                    </div>
                </div>
                :
                <div>
                    <button className="border-secondary form-control bg-transparent cell position-relative m-0" style={{"width":"100%"}}>
                        <div className="btn-group m-0">
                            <button  onClick={() => {this.shiftModal({shifts: dayShifts, clientid:data.clientid, name: data.name, address:data.address, day : data.day})}} className="btn btn-outline-dark btn-full btn-sm m-1 " type="button"  aria-haspopup="true" aria-expanded="false"><div className="large-screen">Create</div>
                                <div className="small-screen">
                                    <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}overlay={renderTooltip('Create Shift')}>
                                        <i class="bi bi-plus-circle"></i>
                                    </OverlayTrigger>
                                </div>
                            </button>
                        </div>
                        <button onClick={() => {
                            this.executePasteShift({shifts:this.state.copiedShift, clientid:data.clientid, day: data.day})}} className="btn  btn-outline-dark btn-full btn-sm "><div className="large-screen">Paste</div>
                            <div className="small-screen">
                                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip('Paste Shift')}>
                                    <i class="bi bi-clipboard-plus"></i>
                                </OverlayTrigger>
                            </div>
                        </button>
                    </button>
                </div>
            }
        </div>
    }

    render(){
        return(
            <div>
                <div className="mt-3">
                    <Tools 
                    unpdateViewRosterByParticipantsState = {()=>this.setState({viewRosterByParticipants : !this.state.viewRosterByParticipants}, function () {
                        this.updateShiftState({start : this.state.weekStart, end : this.state.weekEnd})
                    })}
                    viewRosterByParticipants = {this.state.viewRosterByParticipants}
                    updateWeekStates={this.updateWeekStates} 
                    updateShiftState={this.updateShiftState} 
                    weekStart={this.state.weekStart}
                    weekEnd={this.state.weekEnd}
                    executeApproveShift={this.executeApproveShift}
                    executeConfirmShift={this.executeConfirmShift} 
                    executeDeleteShift={this.executeDeleteShift}
                    bulkSelectItems = {this.bulkSelectItems}
                    selectedParticipantShiftIDs={this.state.selectedParticipantShiftIDs}></Tools>
                </div>
                <table  style={{"marginBottom":"0.1%"}} className="table table--roster table-borderless position-relative">
                    <thead className="thead-roster" style={{width: "100%"}}>
                        <tr className="tr-roster"  style={{"borderBottom":"solid #000000", width: "100%"}}>
                            <th className="table-col-participant th-roster" scope="col"><input onChange={(event)=>{this.setState({nameSearched: event.target.value});this.searchParticipant(event.target.value)}} style={{"width":"100%"}} className="btn-sm form-control border-secondary me-2 bg-transparent" type="text" placeholder="Search Participant... " autoFocus={true}/></th>
                            <th className="table-col th-roster" scope="col" style={{"textAlign":"center"}}>
                                Monday 
                                <center className="small">{moment(this.state.weekStart).add(0,"days").format('DD/MM/YY')}</center>
                            </th>
                            <th className="table-col th-roster" scope="col" style={{"textAlign":"center"}}>
                                Tuesday
                                <center className="small">{moment(this.state.weekStart).add(1,"days").format('DD/MM/YY')}</center>
                            </th>
                            <th className="table-col th-roster" scope="col" style={{"textAlign":"center"}}>
                                Wednesday
                                <center className="small">{moment(this.state.weekStart).add(2,"days").format('DD/MM/YY')}</center>
                            </th>
                            <th className="table-col th-roster" scope="col" style={{"textAlign":"center"}}>
                                Thursday
                                <center className="small">{moment(this.state.weekStart).add(3,"days").format('DD/MM/YY')}</center>
                            </th>
                            <th className="table-col th-roster" scope="col" style={{"textAlign":"center"}}>
                                Friday
                                <center className="small">{moment(this.state.weekStart).add(4,"days").format('DD/MM/YY')}</center>
                            </th>
                            <th className="table-col th-roster" scope="col" style={{"textAlign":"center"}}>
                                Saturday
                                <center className="small">{moment(this.state.weekStart).add(5,"days").format('DD/MM/YY')}</center>
                            </th>
                            <th className="table-col th-roster" scope="col" style={{"textAlign":"center"}}>
                                Sunday
                                <center className="p-r">{moment(this.state.weekStart).add(6,"days").format('DD/MM/YY')}</center>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="tbody-roster" >
                        {
                            this.state.shifts.map((item)=>
                            <tr className="tr-roster">
                                <td className="table-col-participant form-control m-1 cell td-roster">
                                    <div className="text-wrap text-dark  text"><b>{item.name}</b><br></br><small className="text"><i class="bi bi-calendar-event"></i> {item.totalHoursServiced} <small>Wkly Hrs </small>| <i class="bi bi-currency-dollar"></i>{item.weeklyTotalServiceAmount}</small></div>
                                </td>
                                <td onDrop={(event)=>{this.drop(event)}} onDragOver={(event)=>{this.allowDrop(event)}} onDragEnd={()=>{this.onDragEnded()}}    onDragEnter={()=>this.onDragEnter({day: moment(this.state.weekStart).add(0,"days").format('MM/DD/YYYY'), clientid:item.clientid})}  className="table-col td-roster" >{this.shiftsDisplaying({day: moment(this.state.weekStart).add(0,"days").format('MM/DD/YYYY'), clientid: item.clientid, name:item.name, address: item.address,shifts: item.shifts})}</td>
                                <td onDrop={(event)=>{this.drop(event)}} onDragOver={(event)=>{this.allowDrop(event)}} onDragEnd={()=>{this.onDragEnded()}}    onDragEnter={()=>this.onDragEnter({day:moment(this.state.weekStart).add(1,"days").format('MM/DD/YYYY'), clientid:item.clientid})}  className="table-col td-roster"  >{this.shiftsDisplaying({day: moment(this.state.weekStart).add(1,"days").format('MM/DD/YYYY'), clientid: item.clientid, name:item.name , address: item.address, shifts: item.shifts})}</td>
                                <td onDrop={(event)=>{this.drop(event)}} onDragOver={(event)=>{this.allowDrop(event)}} onDragEnd={()=>{this.onDragEnded()}}    onDragEnter={()=>this.onDragEnter({day:moment(this.state.weekStart).add(2,"days").format('MM/DD/YYYY'), clientid:item.clientid})}  className="table-col td-roster"  >{this.shiftsDisplaying({day: moment(this.state.weekStart).add(2,"days").format('MM/DD/YYYY'), clientid: item.clientid, name:item.name , address: item.address, shifts: item.shifts})}</td>
                                <td onDrop={(event)=>{this.drop(event)}} onDragOver={(event)=>{this.allowDrop(event)}} onDragEnd={()=>{this.onDragEnded()}}    onDragEnter={()=>this.onDragEnter({day:moment(this.state.weekStart).add(3,"days").format('MM/DD/YYYY'), clientid:item.clientid})}  className="table-col td-roster"  >{this.shiftsDisplaying({day: moment(this.state.weekStart).add(3,"days").format('MM/DD/YYYY'), clientid: item.clientid, name:item.name , address: item.address, shifts: item.shifts})}</td>
                                <td onDrop={(event)=>{this.drop(event)}} onDragOver={(event)=>{this.allowDrop(event)}} onDragEnd={()=>{this.onDragEnded()}}    onDragEnter={()=>this.onDragEnter({day:moment(this.state.weekStart).add(4,"days").format('MM/DD/YYYY'), clientid:item.clientid})}  className="table-col td-roster"  >{this.shiftsDisplaying({day: moment(this.state.weekStart).add(4,"days").format('MM/DD/YYYY'), clientid: item.clientid, name:item.name , address: item.address, shifts: item.shifts})}</td>
                                <td onDrop={(event)=>{this.drop(event)}} onDragOver={(event)=>{this.allowDrop(event)}} onDragEnd={()=>{this.onDragEnded()}}    onDragEnter={()=>this.onDragEnter({day:moment(this.state.weekStart).add(5,"days").format('MM/DD/YYYY'), clientid:item.clientid})}  className="table-col td-roster"  >{this.shiftsDisplaying({day: moment(this.state.weekStart).add(5,"days").format('MM/DD/YYYY'), clientid: item.clientid, name:item.name , address: item.address, shifts: item.shifts})}</td>
                                <td onDrop={(event)=>{this.drop(event)}} onDragOver={(event)=>{this.allowDrop(event)}} onDragEnd={()=>{this.onDragEnded()}}    onDragEnter={()=>this.onDragEnter({day:moment(this.state.weekStart).add(6,"days").format('MM/DD/YYYY'), clientid:item.clientid})}  className="table-col td-roster"  >{this.shiftsDisplaying({day: moment(this.state.weekStart).add(6,"days").format('MM/DD/YYYY'), clientid: item.clientid, name:item.name , address: item.address, shifts: item.shifts})}</td>
                            </tr>
                        )
                        }
                    </tbody>
                </table>
                <div className = "mb-4">
                    <ShiftKeyComponet></ShiftKeyComponet>
                </div>
                <ShiftModal viewRosterByParticipants={this.state.viewRosterByParticipants} weekstart ={this.state.weekStart} weekend = {this.state.weekEnd}  close={this.closeModel} executeConfirmShift={this.executeConfirmShift} executeApproveShift={this.executeApproveShift} executeDeleteShift={this.executeDeleteShift} updateParticipantShifts={this.updateParticipantShifts} tabkey={this.state.tabkey} getParticipantServices={this.getParticipantServices} executeSaveShift={this.executeSaveShift} show={this.state.showModal} participantServices={this.state.participantServices} workers={this.state.workers} clients={this.state.clients} data={{selectedPerson: this.state.selectedPerson, shifts: this.state.dayShifts ,day: this.state.selectedShiftDate}}></ShiftModal>
                    
            </div>
      )
    }
}