import React from 'react';
import moment from 'moment'
import {  Dropdown } from 'react-bootstrap';
import "../../assets/notification.css";
import RosterController from '../../controllers/roster';


export default class Notifications extends React.Component {
    render() {
        return (
            <>
                <Dropdown.Menu >
                    <div  style = {{minWidth : "350px", maxHeight : "500px", overflowY : "scroll"}} className = "m-2">
                        <div className = "row">
                            <h6 class="mb-1 m-2 mt-0">Swapped Shift(s)</h6>
                            {
                                this.props.transferedShifts.map((row)=>
                                    <div className="text-dark dropdown-notification row mr0 m-1 mb-0 mt-0 mr1">
                                            <div className="d-flex p-3 border-bottom m-1" href="#">
                                                <div class="">
                                                    <div className="row">
                                                        <div className = "h7">
                                                            <i class="bi bi-person-circle"></i> {row.shiftowner.firstname} {row.shiftowner.lastname}
                                                            <button onClick = {()=>RosterController.approveShiftTransfer(row.id).then(res=>this.props.getUserDetails())} style = {{height : "23px"}} className = "btn btn-sm btn-success pt-0 m-1 mt-0 mb-0 float-end">Accept</button>
                                                            <button onClick = {()=>RosterController.declineShiftTransfer(row.id).then(res=>this.props.getUserDetails())}  style = {{height : "23px"}} className = "btn btn-sm btn-danger pt-0 m-1 mt-0 mb-0 float-end">Decline</button>
                                                        </div>
                                                    </div>
                                                    <small className = "text-muted"> <i class="bi bi-calendar-event"></i> {`${moment(row.shift.date).format('dddd')} ${moment(row.shift.date).format('DD')} ${moment(row.shift.date).format('MMMM')} ${moment(row.shift.date).format('yyyy')} | `} <i class="bi bi-alarm"> {`${row.shift.starttime.split(":")[0]}:${row.shift.starttime.split(":")[1]} - ${row.shift.endtime.split(":")[0]}:${row.shift.endtime.split(":")[1]}`}</i></small>
                                                </div>                            
                                            </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className = "row">
                            <h6 class="mb-1 m-2 mt-0">Open Shift(s)</h6>
                            {
                                this.props.openShifts.map((row)=>
                                    <div className="text-dark dropdown-notification row mr0 m-1 mb-0 mt-0 mr1">
                                            <div className="d-flex p-3 border-bottom m-1" href="#">
                                                <div class="row">
                                                    <div className="row">
                                                        <div className = "h7">
                                                            <i class="bi bi-person-circle"></i> Open Shist
                                                            <button onClick = {()=>RosterController.acceptOpenShifts(row.id).then(res=>this.props.getUserDetails())} style = {{height : "23px"}} className = "btn btn-sm btn-success float-end pt-0 m-1 mt-0 mb-0">Accept</button>
                                                        </div>
                                                    </div>
                                                    <small className = "text-muted"> <i class="bi bi-calendar-event"></i> {`${moment(row.date).format('dddd')} ${moment(row.date).format('DD')} ${moment(row.date).format('MMMM')} ${moment(row.date).format('yyyy')} | `} <i class="bi bi-alarm"> {`${row.starttime.split(":")[0]}:${row.starttime.split(":")[1]} - ${row.endtime.split(":")[0]}:${row.endtime.split(":")[1]}`}</i></small>
                                                </div>                            
                                            </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
            </Dropdown.Menu>
        </>
        )
    }
}