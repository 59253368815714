import React from 'react';
import Roster from '../components/roster';
import NavigationBar from '../components/nav'
import Participants from '../components/participants'

export default class ParticipantsView extends React.Component {
    render() {
        return (
            <>
                <Participants/>
            </>
        )
      }
}