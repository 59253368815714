import React from 'react';
import axios from 'axios';
import {BrowserRouter,Routes, Route, Switch} from "react-router-dom";
import RosterView from '../views/roster'
import ReportView from '../views/reports'
import ParticipantView from '../views/participants'
import DashboardView from '../views/dashboard';
import Logs from '../components/SystemAudit';
import TimeSheet from '../components/TimeSheet';
import NavigationBar from '../components/nav';
import Invoices from '../components/Invoices';
import RosterController from '../controllers/roster';

export default class RoutesConfig extends React.Component {
    constructor(props){
        super(props);
        this.state = {
          permissions : []
        }
      }

    getMyPermissions = () => {
        axios.get(RosterController.extractToken().api+"users/my/permissions?token="+RosterController.extractToken().token).then(res=>{
          this.setState({permissions : res.data.res})
        }).catch(error =>{
          console.log(error)
        })
      }
    
      componentDidMount () {
        this.getMyPermissions();
      }
    render() {
        return (
            <BrowserRouter>
                <NavigationBar  permissions = {this.state.permissions} />
                <Routes>
                    <Route exact redirect="/" to="/dashboard"/>
                    <Route exact path="/dashboard" element={<DashboardView permissions = {this.state.permissions} />}/>
                    <Route exact path="/roster" element={<RosterView permissions = {this.state.permissions}  />}/>
                    <Route exact path="/timesheets" element={<TimeSheet permissions = {this.state.permissions}  />}/>
                    <Route exact path="/invoices" element={<Invoices permissions = {this.state.permissions}  />}/>
                    <Route exact path="/participants" element={<ParticipantView permissions = {this.state.permissions}  />}/>
                    <Route exact path="/reports" element={<ReportView permissions = {this.state.permissions}  />}/>
                    <Route exact path="/audit" element={<Logs  permissions = {this.state.permissions}  />}> </Route>
                </Routes>
          </BrowserRouter>
        )
      }
}