import React from 'react'
import '../../assets/tools.css'
import moment from 'moment'
import {  Dropdown } from 'react-bootstrap';
import DatePicker from  "react-datepicker"
import CopyWeekModal from "../modals/copyWeekModal";
// import { Button, Modal, Card, Popover, Nav, Dropdown } from 'react-bootstrap';
import ToolsController from '../../controllers/tools';


export default class Tools extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            weekStart : props.weekStart,
            weekEnd : props.weekEnd,
            showCopyWeekModal : props.showCopyWeekModal,
            viewRosterByParticipants : props.viewRosterByParticipants
        }
    }
    componentDidMount(){
        var start=ToolsController.calculateWeekRange().start;
        var end = ToolsController.calculateWeekRange().end;
        // this.setState({weekStart:start, weekEnd: end});
        this.props.updateShiftState({start:start, end:end});
        this.props.updateWeekStates({start:start, end:end });
    }
    componentDidUpdate(prevProps) {
        if (prevProps.weekEnd !== this.props.weekEnd) {
            this.setState({weekStart : this.props.weekStart, weekEnd : this.props.weekEnd})
          }
        if (prevProps.viewRosterByParticipants !== this.props.viewRosterByParticipants){
            this.setState({viewRosterByParticipants : this.props.viewRosterByParticipants})
        }
      }
    render(){
        return(
            <div style={{"width":"100%"}} className = "row">
                <div className = "col-"  style={{width : "29%"}}>
                    {/* <small className="tool-button btn btn-sm m-0">
                        <div class="input-group">
                            <span class="input-group-text btn btn-sm btn-outline-secondary"><i class="bi bi-geo-alt-fill"></i></span>
                            <input class="tool-button btn btn-sm form-control border-secondary bg-transparent " placeholder="Choose Location to Roster " aria-label="With textarea"></input>
                        </div>
                    </small> */}
                    <div className=" btn-group">
                        <button onClick={()=>{
                            var start=ToolsController.getPreviousWeek({start:this.props.weekStart, end : this.props.weekEnd}).start;
                            var end = ToolsController.getPreviousWeek({start:this.props.weekStart, end : this.props.weekEnd}).end;
                            this.setState({weekStart: start, weekEnd: end});
                            this.props.updateWeekStates({start:start, end:end });
                            this.props.updateShiftState({start:start, end:end});
                        }} type="button" className="btn btn-sm btn-outline-secondary">&#10094;</button>
                        <button type="button" className="btn btn-sm btn-outline-secondary">
                            <span data-feather="calendar"></span>{`${moment(this.props.weekStart).format('ddd')} ${moment(this.props.weekStart).format('DD')} ${moment(this.props.weekStart).format('MMM')}`} - {moment(this.props.weekEnd).format('ddd')} {moment(this.props.weekEnd).format('DD')} {moment(this.props.weekEnd).format('MMM')}</button>
                        <button onClick={()=>{
                            var start=ToolsController.getNextWeek({start:this.props.weekStart, end : this.props.weekEnd}).start;
                            var end = ToolsController.getNextWeek({start:this.props.weekStart, end : this.props.weekEnd}).end;
                            this.props.updateWeekStates({start:start, end:end });
                            this.props.updateShiftState({start:start, end:end});
                        }} type="button" className="btn btn-sm btn-outline-secondary">&#10095;</button>
                    </div>
                </div>
                <div  className = "col-" style={{width:"34%"}}><div id="notifications"></div></div>
                <div className = "col-" style={{width : "37%"}}>
                    <div style={{float:"right"}}>
                        {/* <button type="button" className="btn btn-outline-danger btn-sm m-1" onClick={()=>{this.props.executeDeleteShift(this.props.selectedParticipantShiftIDs)}}><i class="bi bi-trash"></i></button> */}
                        <button onClick={()=>this.props.updateShiftState({start:this.props.weekStart, end:this.props.weekEnd})} type="button" className=" btn btn-outline-primary btn-sm m-1" title="Refresh"><i class="bi bi-arrow-clockwise"></i></button>
                        <button title = "Process and Send to Xero" type="button" className="  btn btn-success btn-sm" onClick={()=>{this.props.executeApproveShift(this.props.selectedParticipantShiftIDs)}}>Process</button>
                    </div>
                </div>
                <CopyWeekModal closeModal={()=>{this.setState({showCopyWeekModal : false})}} showCopyWeekModal ={this.state.showCopyWeekModal} weekend = {this.state.weekEnd} weekstart = {this.state.weekStart}></CopyWeekModal>     
            </div>
        )
    }
}