import React from 'react';
import Roster from '../components/roster';

export default class RosterView extends React.Component {
    render() {
        return (
            <>
                <div style={{"height":"100%","marginRight":"1%", "marginLeft":"1%"}}>
                    <Roster permissions = {this.props.permissions}></Roster>
                </div>
            </>
        )
      }
}