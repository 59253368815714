import React from "react";
import { Modal} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead'; 
import RosterController from '../../controllers/roster';

export default class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            show:  props.show,
            reason : "",
            transferedTo : null,
            shiftid : null
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show) {
            this.setState({show: this.props.show})
          }
      }
      
    render(){
        return (
            <Modal  size = "l" className="fade" show={this.props.show} onHide={()=>{this.props.modalFuction()}} >
                <form onSubmit = {(event)=>{
                    event.preventDefault();
                    RosterController.transferShift({shiftid : this.state.shiftid, to : this.state.transferedTo, reason : this.state.reason}).then(res=>{
                        this.props.getUserDetails();
                        this.props.modalFuction()
                    })
                }}>
                    <Modal.Body>
                        <button type="button" className="close btn position-absolute end-0 top-0" onClick={()=>{this.props.modalFuction()}}>
                            &times;
                        </button>
                        <center className="start-50 m-2 h3">Shift Transfer</center>
                        <hr></hr>
                        <div className="mt-3 mb-2">

                            <div className = " row  m-2 ">
                                <Typeahead
                                    id="custom-selections-example"
                                    newSelectionPrefix="Participant: "
                                    options={this.props.staff}
                                    onChange={(event)=>{
                                        if(event[0]){
                                            this.setState({transferedTo : event[0].value})
                                        }
                                    }}
                                    placeholder="Seleact Employee you wish to transfer it to..."
                                />
                            </div>

                            <div className = " row  m-2 ">
                                <Typeahead
                                    id="custom-selections-example"
                                    newSelectionPrefix="Participant: "
                                    options={this.props.shifts()}
                                    onChange={(event)=>{
                                        if(event[0]){
                                            this.setState({shiftid : event[0].value})
                                        }
                                    }}
                                    placeholder="Seleact the shifts you wish to transfer..."
                                />
                            </div>
                            
                            <div className = "row m-2">
                                <textarea onChange = {(event)=>this.setState({reason : event.target.value})} placeholder = "Type reason for the shift transfer here..." className ="teaxtarea border-primary form-control col m-2" />
                            </div>
                            
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type = "submit" className="btn-primary btn btn-small">Transfer</button>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }
}