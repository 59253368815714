import React from "react"
import moment from "moment"
import date from "date-and-time";
import { Button, Modal, Tabs, Tab} from 'react-bootstrap';
import "../../assets/shiftModal.css"
import { Typeahead } from 'react-bootstrap-typeahead'; 
import 'react-bootstrap-typeahead/css/Typeahead.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2"
import 'animate.css'; //Aminitions for arlets for

export default class ShiftModal extends React.Component { 
    constructor(props) {
        super(props);
        this.state={
            show:props.show,
            clientid : null,
            name : "",
            serviceid:null,
            address: "",
            date:"",
            starttime:"",
            endtime:"",
            notes : "",
            staffid: null,
            priceperhour:0,
            calculatedDuration:0,
            key:props.tabkey,
            shiftListToBeEdited:[],
            shifts:[],
            selectedShiftIds:[],
            weekstart: "", //this.props.weekstart,
            weekend :"", //this.props.weekend,
            weekdays : [],
            upcommingweeks:[],
            followingweeks: [],
            selectedrecurrancedates: [],
            viewRosterByParticipants : props.viewRosterByParticipants,
            showParticipant : props.viewRosterByParticipants,
        }
    }

    weekDays = (data) =>{
        var dates=[];
        var pos=0;
        while(moment(data.weekstart).add(pos,'days').format('MM/DD/YYYY')<=moment(data.weekend).format('MM/DD/YYYY')){
            dates.push(moment(data.weekstart).add(pos,'days').format('MM/DD/YYYY'));
            pos++;
        }
        this.setState({ selectedrecurrancedates:[], weekdays: dates});
    }
    followingWeeks = (data) => {
        var start = moment(data.weekstart).format('MM-DD-YYYY');
        start = moment(start).add(0, 'days').format('MM-DD-YYYY');
        var end = moment(start).add(6, 'days').format('MM-DD-YYYY')
        var arr = [];
        for (let index = 0; index < 4; index++) {
          var end = moment(start).add(6, 'days').format('MM-DD-YYYY');
          arr.push({ weekstart: start, weekend: end });
          start = moment(end).add(1, 'days').format('MM-DD-YYYY');
        }
        
        this.setState({followingweeks: arr});
        this.weekDays(data);

    } 
    componentDidUpdate(prevProps) {

        if (prevProps.tabkey !== this.props.tabkey) {
            this.weekDays({weekstart: this.props.weekstart, weekend: this.props.weekend});
            this.setState({key: this.props.tabkey})
        }
        if (prevProps.show !== this.props.show) {
            this.followingWeeks({weekstart: this.props.weekstart, weekend: this.props.weekend});
            this.weekDays({weekstart: this.props.weekstart, weekend: this.props.weekend});
            this.setState({show: this.props.show})
          }
        //   console.log(this.props.data.shifts)
        if (prevProps.data.shifts !== this.props.data.shifts) {
            this.weekDays({weekstart: this.props.weekstart, weekend: this.props.weekend});
            this.setState({shifts: this.props.data.shifts})
          }

        if (prevProps.weekend !== this.props.weekend) {
            this.weekDays({weekstart: this.props.weekstart, weekend: this.props.weekend});
            this.followingWeeks({weekstart: this.props.weekstart, weekend: this.props.weekend});
          }
        if (prevProps.viewRosterByParticipants!==this.props.viewRosterByParticipants){
            this.setState({viewRosterByParticipants:this.props.viewRosterByParticipants, showParticipant : this.props.viewRosterByParticipants})
        }
        if (prevProps.data.selectedPerson.id!==this.props.data.selectedPerson.id){
            this.setState({clientid : this.props.viewRosterByParticipants? this.props.data.selectedPerson.id:null, staffid: !this.props.viewRosterByParticipants? this.props.data.selectedPerson.id:null})
        }
      }


    calculateDurationOfShift = (params) =>{
        var starttime= date.parse(params.starttime.split(":")[0]+":"+params.starttime.split(":")[1], 'HH:mm:ss A',true);
        var endtime =date.parse(params.endtime.split(":")[0]+":"+params.endtime.split(":")[1], 'HH:mm:ss A',true);
        var  duration = date.subtract(endtime, starttime).toHours();
        duration= Number((duration).toFixed(2));
        duration = duration<0?24+duration:duration;
        this.setState({calculatedDuration:duration})
        console.log(duration)
        return duration;
    }
    setKey=(key)=>{
        this.setState({key:key})
    }

    onCloseModal = () =>{
        if (this.state.shiftListToBeEdited.length==0) {
            this.props.close(); this.setState({shiftListToBeEdited:[], selectedShiftIds:[]})
        }else{
            var swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                  confirmButton: 'btn btn-success m-1',
                  cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
              })
              swalWithBootstrapButtons.fire({
                showCancelButton: true,
                confirmButtonText: 'Save edited changes!',
                cancelButtonText: 'Discard edited changes!',
                reverseButtons: true,
                showClass: {
                  popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp'
                }
              }).then(answr=>{
                if(answr.isConfirmed){
                    this.props.updateParticipantShifts(this.state.shiftListToBeEdited).then(res=>{this.props.close();this.setState({shiftListToBeEdited: []})});
                }else{
                    this.props.close(); this.setState({shiftListToBeEdited:[], selectedShiftIds:[]})
                }
              })
        }
    }
    
    render(){
        return(
        <>
            <Modal size="lg" className="shiftModal participantShiftsModal" show={this.state.show} onHide={()=>{this.setState({selectedrecurrancedates:[]});this.props.close()}} >
               
                    <h3 style={{display: "flex", justifyContent: "center",alignItems: "center", marginBottom:"2%", marginTop:"1%"}}> {this.props.data.selectedPerson.name}</h3>
                    <Tabs
                    id="controlled-tab-example"
                    activeKey={this.state.key}
                    onSelect={(k) => this.setKey(k)}
                    className="mb-3"
                    >
                    <Tab eventKey="home" title="Add New Shift">
                        <form 
                        onSubmit={
                                (event)=>{
                                    this.state.selectedrecurrancedates.push(this.state.date?this.state.date:this.props.data.day);
                                    this.state.viewRosterByParticipants?
                                    this.props.executeSaveShift({
                                        staffid:this.state.staffid,
                                        clientid: this.state.clientid == null? this.props.data.selectedPerson.id : this.state.clientid, 
                                        starttime: this.state.starttime, 
                                        endtime:this.state.endtime, 
                                        notes : this.state.notes,
                                        costperhour:this.state.costperhour?this.state.costperhour:null,
                                        address:this.state.address?this.state.address:this.props.data.selectedPerson.address, 
                                        date:this.state.date?this.state.date:this.props.data.day, 
                                        selectedrecurrancedates: this.state.selectedrecurrancedates, 
                                        serviceid:this.state.serviceid
                                        }, 
                                        event
                                    )
                                    :
                                    this.props.executeSaveShift({
                                        clientid: this.state.clientid, 
                                        staffid:this.state.staffid == null?  this.props.data.selectedPerson.id : this.state.staffid,
                                        starttime: this.state.starttime, 
                                        endtime:this.state.endtime, 
                                        notes : this.state.notes,
                                        costperhour:this.state.costperhour?this.state.costperhour:null,
                                        address:this.state.address?this.state.address:this.props.data.selectedPerson.address, 
                                        date:this.state.date?this.state.date:this.props.data.day, 
                                        selectedrecurrancedates: this.state.selectedrecurrancedates, 
                                        serviceid:this.state.serviceid
                                        }, 
                                        event
                                    )
                                }
                            }>
                            
                            <Modal.Body>
                                <div className = "row">
                                    <div className="col-sm-12 col-md-7 col-lg-7">
                                        <div className="row">
                                            <div class="mb-4 col-6">
                                                <label for="date" class="form-label input-labels">Select Employee</label>
                                                <Typeahead
                                                    allowNew
                                                    id="custom-selections-example"
                                                    newSelectionPrefix="Participant: "
                                                    options={this.props.workers}
                                                    defaultInputValue={this.props.viewRosterByParticipants? "" : this.props.data.selectedPerson.name}
                                                    onChange={(event)=>{
                                                        if(event[0]){
                                                            this.setState({staffid : event[0].value});
                                                        }
                                                    }}
                                                    placeholder="Seleact Staff to work with the Participant..."
                                                />
                                            </div>
                                            <div className = "col-6">
                                                <label for="actiont" class="form-label input-labels"></label>
                                                <div onClick = {()=>this.setState({showParticipant : !this.state.showParticipant})} style = {{cursor:"pointer"}} className = "mt-2" id = "actiont">
                                                    <input checked = {this.state.showParticipant} type = "checkbox" className = ""></input> Add Participant  
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.state.showParticipant?
                                                <div className="row">
                                                    <div class="mb-3 col-6">
                                                        <label for="exampleInputEmail1" class="form-label input-labels">Participant</label>
                                                        <Typeahead
                                                            allowNew
                                                            id="custom-selections-example"
                                                            newSelectionPrefix="Participant: "
                                                            options={this.props.clients}
                                                            defaultInputValue={this.props.viewRosterByParticipants?this.props.data.selectedPerson.name:""}
                                                            onChange={(event)=>{
                                                                if(event[0]){
                                                                    this.setState({address:event[0].address,clientid: event[0].value})
                                                                    this.props.getParticipantServices(event[0].value);
                                                                }
                                                            }}
                                                            placeholder="Seleact Participant..."
                                                        />
                                                    </div>
                                                    <div class="mb-3 col-6">
                                                        <label for="exampleInputPassword1" class="form-label input-labels">Service</label>
                                                        <Typeahead
                                                            className=".form-control-inputs"
                                                            allowNew
                                                            id="custom-selections-example"
                                                            newSelectionPrefix="Service: "
                                                            options={this.props.participantServices}
                                                            onChange={(event)=>{
                                                                if(event[0]){
                                                                    this.setState({costperhour:event[0].servicePrice, serviceid: event[0].value})
                                                                }
                                                            }}
                                                            placeholder="Select Particpant Service..."
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                ""
                                            }
                                        
                                            <div className="row"> 
                                                <div class="mb-3 col-6">
                                                    <label for="location" class="form-label input-labels">Location</label>
                                                        <textarea onChange ={(event)=>{this.setState({address : event.target.value})}} defaultValue={this.state.address?this.state.address:this.props.data.selectedPerson.address} type="text" class=" border-primary" id="location" />
                                                </div>
                                                <div class="mb-3 col-6">
                                                    <label for="location" class="form-label input-labels">Notes</label>
                                                        <textarea onChange ={(event)=>{ this.setState({notes : event.target.value})}} type="text" class=" border-primary" id="location" />
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div class="mb-1 col-3">
                                                    <label for="date" class="form-label input-labels">Date</label>
                                                        <DatePicker className="form-control border-primary " selected={new Date(this.state.date?this.state.date:this.props.data.day)} onChange={(date)=>{this.setState({date:moment(date)})}} />
                                                </div>

                                                <div class="mb-2 col-3">
                                                    <label for="starttime" class="form-label input-labels">Start Time</label>
                                                    <input required type="time" onChange={(event)=>{
                                                        this.setState({starttime:event.target.value}); 
                                                        this.calculateDurationOfShift({starttime: event.target.value, endtime: this.state.endtime});
                                                        }} class="form-control border-primary " id="starttime" aria-describedby="emailHelp"/>
                                                </div>

                                                <div class="mb-2 col-3">
                                                    <label for="endtime" class="form-label input-labels">End Time</label>
                                                    <input required onChange={(event)=>{
                                                        this.setState({endtime:event.target.value}); 
                                                        this.calculateDurationOfShift({starttime: this.state.starttime, endtime: event.target.value})
                                                        }} type="time" class="form-control border-primary " id="endtime"/>
                                                </div>
                                                
                                                <div class="mb-2 col-3">
                                                    <label for="price" class="form-label input-labels">Rate $/Hr</label>
                                                    <input onChange={(event)=>{this.setState({costperhour:event.target.value})}}  defaultValue={this.state.costperhour}  type="text" class="form-control border-primary" id="costperhour" name="costperhour"/>
                                                </div>
                                                
                                            </div>
                                        </div>

                                        <div style = {{borderLeft:" solid .51px", borderColor:"blue"}} className="col-sm-12 col-md-5 col-lg-5  border-left ">
                                            <div className="row">
                                                <small className = "row mt-2">
                                                    <div className = "col-4">Select Week</div> 
                                                    <div className="col-8">
                                                        <select  onChange={(event)=>{this.weekDays(this.state.followingweeks[this.state.followingweeks.findIndex(x=>x.weekstart==event.target.value)])}} className="form-control">
                                                            <option >-- Repeat in This Week --</option>
                                                            {
                                                                this.state.followingweeks.map(item=>
                                                                    <option value={`${item.weekstart}`} key={item} >{item.weekstart} to {item.weekend}</option>)
                                                            }
                                                        </select>
                                                    </div> 
                                                </small>
                                                <div className = "row mt-2">
                                                    <small className = "row mt-2 m-1 mb-0">Select custom days to Repeat:</small>
                                                    <div style = {{padding : "0px", width : "100%", display: "inherit", marginLeft : "1%"}} >
                                                        {
                                                            this.state.weekdays.map(item=>
                                                                <div onClick={(event)=>{
                                                                    var arr = this.state.selectedrecurrancedates;
                                                                    var index = arr.findIndex(x => x == item);
                                                                    if (index >= 0) {
                                                                        arr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));
                                                                    } else {
                                                                        arr.push(item);
                                                                    }
                                                                    this.setState({selectedrecurrancedates:arr});
                                                                    }} value={item} 
                                                                    style = {{peddingRight :"0px", cursor: "pointer", width :"14%"}} class={` mt-0 mb-0`}>
                                                                    <input className = ""  type = "checkbox" checked = {this.state.selectedrecurrancedates.includes(item)} ></input> <small className="" > {moment(item).format("dd")}</small>
                                                                </div>
                                                                )
                                                        }
                                                    </div>
                                                </div>

                                                <small className = "mt-2 m-1 mb-0 row"> Shift Length:  {this.state.calculatedDuration} Hours </small>
                                                <small className = "mt-2 m-1 mb-0 row"> Service Charge Per Hour: ${this.state.costperhour} </small>
                                                <small className = "mt-2 m-1 mb-0 row"> Total Service Charge: ${Number(this.state.costperhour*this.state.calculatedDuration).toFixed(2)}  </small>
                                                
                                            </div>
                                        </div>
                                </div>
                            </Modal.Body>
                                    <Modal.Footer>
                                    <Button variant="danger" onClick={()=>{this.setState({address:"", selectedrecurrancedates : [],starttime:"",date:"",endtime:"",clientid: null,serviceid:0, costperhour:0});this.props.close()}}>
                                        Close
                                    </Button>
                                    <Button type="submit" variant="primary" >
                                        Save Shift
                                    </Button>
                                </Modal.Footer>
                                </form>
                            </Tab>
                            {/* LOCATION BASED SHIFT(S) */}
                            <Tab eventKey="locationshifts" title="Location Shifts">
                            <Modal.Body>
                                    <div className="table-shifts"> 
                                        <table class="table table-hover">
                                            <thead>
                                                <tr className="participant-shift-row">
                                                <th  scope="col">
                                                    <input
                                                        checked={this.state.selectedShiftIds.length==this.state.shifts.length&&this.state.shifts.length!=0?"checked":""}
                                                        onClick={()=>{
                                                            var list=[];
                                                            if(this.state.selectedShiftIds.length>0){
                                                                this.setState({selectedShiftIds:[]})
                                                            }else{
                                                                this.state.shifts.forEach(element=>{
                                                                    list.push(element.id);
                                                                })
                                                            }
                                                            this.setState({selectedShiftIds:list});
                                                        }}
                                                     type="checkbox">
                                                    </input></th>
                                                <th  scope="col">Date</th>
                                                <th  scope="col">Start</th>
                                                <th scope="col">End </th>
                                                <th scope="col">{this.state.viewRosterByParticipants?`Staff` : `Client`}</th>
                                                <th  scope="col">Cost</th>
                                                <th  scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.shifts.map(item=>
                                                        !(item.clientid>0)?
                                                        <tr className={!item.approved?"participant-shift-row":item.approved&&!item.confirmed?"participant-shift-row day-shift-approved":"participant-shift-row day-shift-confirmed"}>
                                                            <td>
                                                                <input 
                                                                    checked={this.state.selectedShiftIds.findIndex(x=>x==item.id)>=0?"checked":""} 
                                                                    onClick={(event)=>{
                                                                        var list=this.state.selectedShiftIds;
                                                                        const index=list.findIndex(x=>x==event.target.value);
                                                                        if(index>=0){
                                                                            list = list.slice(0, index).concat(list.slice(index + 1, list.length));
                                                                        }else{
                                                                            list.push(event.target.value);
                                                                        }
                                                                        this.setState({selectedShiftIds:list});
                                                                    }}
                                                                    value={item.id} type="checkbox" >
                                                                </input></td>
                                                            <td>
                                                                <div id={`date-${item.id}`} onClick={()=>{
                                                                        document.getElementById(`edit-date-${item.id}`).style.display='block'
                                                                        document.getElementById(`date-${item.id}`).style.display='none';
                                                                    }}>
                                                                    <i id={`date-${item.id}`} onClick={()=>{}} class="bi bi-pencil-square"></i> {`${moment(item.date).format("ddd")} ${moment(item.date).format("DD")} ${moment(item.date).format("MMM")}`}
                                                                </div>
                                                                <div style={{"display":"none"}} id={`edit-date-${item.id}`}>
                                                                    
                                                                    <input id={item.id} type="date" className="form-control border-primary" defaultValue={item.date}
                                                                        onKeyDown={(event)=>{
                                                                            if(event.key=="Enter"){
                                                                                document.getElementById(`date-${item.id}`).style.display='block'
                                                                                document.getElementById(`edit-date-${item.id}`).style.display='none';
                                                                            }
                                                                        }}
                                                                        onChange={(event)=>{
                                                                            document.getElementById(`date-${item.id}`).innerHTML = `${moment(event.target.value).format("ddd")} ${moment(event.target.value).format("DD")} ${moment(event.target.value).format("MMM")}`;
                                                                            
                                                                            var list= this.state.shiftListToBeEdited;
                                                                                const index= list.findIndex(x=>x.id==item.id);
                                                                                if(index<0){
                                                                                    item.date=event.target.value;
                                                                                    this.state.shiftListToBeEdited.push(item)
                                                                                }else{
                                                                                    list[index].date=event.target.value;
                                                                                }
                                                                                this.setState({shiftListToBeEdited: list});
                                                                        }}
                                                                    ></input>

                                                                        <i type="button" class="bi  bi-x-circle-fill cancell-icon m-1" onClick={()=>{
                                                                            document.getElementById(`date-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-date-${item.id}`).style.display='none';
                                                                        }}>Cancel</i> 
                                                                        <i type="button" class="bi  bi-x-circle-fill bi-check2-all m-1 text-success" onClick={()=>{
                                                                            document.getElementById(`date-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-date-${item.id}`).style.display='none';
                                                                        }}>Done</i>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div id={`starttime-${item.id}`} onClick={()=>{
                                                                        document.getElementById(`edit-starttime-${item.id}`).style.display='block'
                                                                        document.getElementById(`starttime-${item.id}`).style.display='none';
                                                                    }}>
                                                                    <i id={`starttime-${item.id}`} onClick={()=>{}} class="bi bi-pencil-square"></i> {`${item.starttime.split(":")[0]}:${item.starttime.split(":")[1]}`}
                                                                </div>
                                                                <div style={{"display":"none"}} id={`edit-starttime-${item.id}`}>
                                                                    <input defaultValue ={item.starttime} 
                                                                    onKeyDown={(event)=>{
                                                                        if(event.key=="Enter"){
                                                                            document.getElementById(`starttime-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-starttime-${item.id}`).style.display='none';
                                                                        }
                                                                    }}
                                                                     onChange={(event)=>{
                                                                                document.getElementById(`starttime-${item.id}`).innerHTML = event.target.value;
                                                                                
                                                                                var list= this.state.shiftListToBeEdited;
                                                                                const index= list.findIndex(x=>x.id==item.id);
                                                                                if(index<0){
                                                                                    item.starttime=event.target.value;
                                                                                    this.state.shiftListToBeEdited.push(item);
                                                                                }else{
                                                                                    list[index].starttime=event.target.value;
                                                                                }
                                                                                this.setState({shiftListToBeEdited: list});
                                                                                
                                                                        }} type="time" class="form-control border-primary" id="starttime" aria-describedby="emailHelp"/>
                                                                    {/* <div className="row"> */}
                                                                        <i type="button" class="bi  bi-x-circle-fill cancell-icon m-1" onClick={()=>{
                                                                                document.getElementById(`starttime-${item.id}`).style.display='block'
                                                                                document.getElementById(`edit-starttime-${item.id}`).style.display='none';
                                                                            }}>Cancel</i> 
                                                                            <i type="button" class="bi  bi-x-circle-fill bi-check2-all m-1 text-success" onClick={()=>{
                                                                                document.getElementById(`starttime-${item.id}`).style.display='block'
                                                                                document.getElementById(`edit-starttime-${item.id}`).style.display='none';
                                                                            }}>Done</i>
                                                                    {/* </div> */}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div id={`endtime-${item.id}`} onClick={()=>{
                                                                        document.getElementById(`edit-endtime-${item.id}`).style.display='block'
                                                                        document.getElementById(`endtime-${item.id}`).style.display='none';
                                                                    }}>
                                                                    <i id={`endtime-${item.id}`} onClick={()=>{}} class="bi bi-pencil-square"></i> {`${item.endtime.split(":")[0]}:${item.endtime.split(":")[1]}`}
                                                                </div>
                                                                <div style={{"display":"none"}} id={`edit-endtime-${item.id}`}>
                                                                    <input defaultValue={item.endtime}
                                                                     onKeyDown={(event)=>{
                                                                            if(event.key=="Enter"){
                                                                                document.getElementById(`endtime-${item.id}`).style.display='block'
                                                                                document.getElementById(`edit-endtime-${item.id}`).style.display='none';
                                                                            }
                                                                        }}
                                                                     onChange={(event)=>{
                                                                            var list= this.state.shiftListToBeEdited;
                                                                            const index= list.findIndex(x=>x.id==item.id);
                                                                            document.getElementById(`endtime-${item.id}`).innerHTML = event.target.value;
                                                                            if(index<0){
                                                                                item.endtime=event.target.value;
                                                                                this.state.shiftListToBeEdited.push(item);
                                                                            }else{
                                                                                list[index].endtime=event.target.value
                                                                            }
                                                                            this.setState({shiftListToBeEdited: list});
                                                                        }}
                                                                     type="time" class="form-control border-primary" id="starttime" aria-describedby="emailHelp"/>
                                                                    
                                                                    <i type="button" class="bi  bi-x-circle-fill cancell-icon m-1" onClick={()=>{
                                                                            document.getElementById(`endtime-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-endtime-${item.id}`).style.display='none';
                                                                        }}>Cancel</i> 
                                                                        <i type="button" class="bi  bi-x-circle-fill bi-check2-all m-1 text-success" onClick={()=>{
                                                                            document.getElementById(`endtime-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-endtime-${item.id}`).style.display='none';
                                                                        }}>Done</i>
                                                                </div>
                                                            </td>
                                                            {/* <td>
                                                                <div id={`service-${item.id}`} onClick={()=>{
                                                                    document.getElementById(`edit-service-${item.id}`).style.display='block'
                                                                    document.getElementById(`service-${item.id}`).style.display='none';
                                                                }}>
                                                                    <i id={`service-${item.id}`} onClick={()=>{}} class="bi bi-pencil-square"> </i>{item.tbl_support_service.support_service}
                                                                </div>
                                                                <div style={{"display":"none"}} id={`edit-service-${item.id}`}>
                                                                    <Typeahead
                                                                        className=".form-control-inputs"
                                                                        allowNew
                                                                        id={`edit-service-${item.id}`}
                                                                        onKeyDown={(event)=>{
                                                                            if(event.key=="Enter"){
                                                                                document.getElementById(`service-${item.id}`).style.display='block'
                                                                                document.getElementById(`edit-service-${item.id}`).style.display='none';
                                                                            }
                                                                        }}
                                                                        newSelectionPrefix="Service: "
                                                                        options={this.props.participantServices}
                                                                        onChange={(event)=>{
                                                                            if(event[0]){
                                                                                document.getElementById(`service-${item.id}`).innerHTML = event[0].label;
                                                                                document.getElementById(`service-${item.id}`).style.display='block';
                                                                                document.getElementById(`edit-service-${item.id}`).style.display='none';
                                                                                var list= this.state.shiftListToBeEdited;
                                                                                const index= list.findIndex(x=>x.id==item.id);
                                                                                if(index<0){
                                                                                    item.serviceid=event[0].value;
                                                                                    item.tbl_support_service.support_service=event[0].label;
                                                                                    this.state.shiftListToBeEdited.push(item);
                                                                                }else{
                                                                                    list[index].serviceid=event[0].value;
                                                                                    list[index].tbl_support_service.support_service=event[0].label;
                                                                                }
                                                                                this.setState({shiftListToBeEdited: list});
                                                                            }
                                                                        }}
                                                                        placeholder="Select Particpant Service..."
                                                                    />
                                                                    <i type="button" class="bi  bi-x-circle-fill cancell-icon m-1" onClick={()=>{
                                                                            document.getElementById(`service-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-service-${item.id}`).style.display='none';
                                                                        }}>Cancel</i> 
                                                                        <i type="button" class="bi  bi-x-circle-fill bi-check2-all m-1 text-success" onClick={()=>{
                                                                            document.getElementById(`service-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-service-${item.id}`).style.display='none';
                                                                        }}>Done</i>
                                                                </div>
                                                            </td> */}
                                                            {
                                                                this.state.viewRosterByParticipants?
                                                                <td>
                                                                    {item.tbluser?
                                                                        <div id={`staff-${item.id}`} onClick={()=>{
                                                                            document.getElementById(`edit-staff-${item.id}`).style.display='block'
                                                                            document.getElementById(`staff-${item.id}`).style.display='none';
                                                                        }}>
                                                                        <i class="bi bi-pencil-square"></i>{item.tbluser.lastname} {item.tbluser.firstname}
                                                                        </div>
                                                                        :
                                                                        <i id={`staff-${item.id}`} onClick={()=>{
                                                                            document.getElementById(`edit-staff-${item.id}`).style.display='block'
                                                                            document.getElementById(`staff-${item.id}`).style.display='none';
                                                                        }} class="bi bi-pencil-square">Asign Staff</i>
                                                                    } 
                                                                    <div style={{"display":"none"}} id={`edit-staff-${item.id}`}>
                                                                        <Typeahead
                                                                            // style={{"display":"none"}}
                                                                            className=".form-control-inputs"
                                                                            allowNew
                                                                            id={`edit-staff-${item.id}`}
                                                                            newSelectionPrefix="Staff: "
                                                                            options={this.props.workers}
                                                                            onKeyDown={(event)=>{
                                                                                if(event.key=="Enter"){
                                                                                    document.getElementById(`staff-${item.id}`).style.display='block'
                                                                                    document.getElementById(`edit-staff-${item.id}`).style.display='none';
                                                                                }
                                                                            }}
                                                                            onChange={(event)=>{
                                                                                if(event[0]){
                                                                                    document.getElementById(`staff-${item.id}`).innerHTML = event[0].label;
                                                                                    document.getElementById(`staff-${item.id}`).style.display='block';
                                                                                    document.getElementById(`edit-staff-${item.id}`).style.display='none';
                                                                                    var list= this.state.shiftListToBeEdited;
                                                                                    const index= list.findIndex(x=>x.id==item.id);
                                                                                    if(index<0){
                                                                                        item.staffid=event[0].value;
                                                                                        this.state.shiftListToBeEdited.push(item);
                                                                                    }else{
                                                                                        list[index].staffid=event[0].value;
                                                                                    }
                                                                                    this.setState({shiftListToBeEdited: list});
                                                                                }
                                                                            }}
                                                                            placeholder="Select Staff Service..."
                                                                        />
                                                                        <i type="button" class="bi  bi-x-circle-fill cancell-icon m-1" onClick={()=>{
                                                                                document.getElementById(`staff-${item.id}`).style.display='block'
                                                                                document.getElementById(`edit-staff-${item.id}`).style.display='none';
                                                                            }}>Cancel</i> 
                                                                            <i type="button" class="bi  bi-x-circle-fill bi-check2-all m-1 text-success" onClick={()=>{
                                                                                document.getElementById(`staff-${item.id}`).style.display='block'
                                                                                document.getElementById(`edit-staff-${item.id}`).style.display='none';
                                                                            }}>Done</i>
                                                                    </div>
                                                                </td>

                                                                :

                                                                <td>
                                                                {item.tblparticipant?
                                                                    <div id={`client-${item.id}`} onClick={()=>{
                                                                        document.getElementById(`edit-client-${item.id}`).style.display='block'
                                                                        document.getElementById(`client-${item.id}`).style.display='none';
                                                                    }}>
                                                                    <i class="bi bi-pencil-square"></i>{item.tblparticipant.lastname} {item.tblparticipant.firstname}
                                                                    </div>
                                                                    :
                                                                    <i id={`client-${item.id}`} onClick={()=>{
                                                                        document.getElementById(`edit-client-${item.id}`).style.display='block'
                                                                        document.getElementById(`client-${item.id}`).style.display='none';
                                                                    }} class="bi bi-pencil-square">Asign Participant</i>
                                                                    } 
                                                                    <div style={{"display":"none"}} id={`edit-client-${item.id}`}>
                                                                        <Typeahead
                                                                            // style={{"display":"none"}}
                                                                            className=".form-control-inputs"
                                                                            allowNew
                                                                            id={`edit-client-${item.id}`}
                                                                            newSelectionPrefix="Participant: "
                                                                            options={this.props.clients}
                                                                            onKeyDown={(event)=>{
                                                                                if(event.key=="Enter"){
                                                                                    document.getElementById(`client-${item.id}`).style.display='block'
                                                                                    document.getElementById(`edit-client-${item.id}`).style.display='none';
                                                                                }
                                                                            }}
                                                                            onChange={(event)=>{
                                                                                if(event[0]){
                                                                                    document.getElementById(`client-${item.id}`).innerHTML = event[0].label;
                                                                                    document.getElementById(`client-${item.id}`).style.display='block';
                                                                                    document.getElementById(`edit-client-${item.id}`).style.display='none';
                                                                                    var list= this.state.shiftListToBeEdited;
                                                                                    const index= list.findIndex(x=>x.id==item.id);
                                                                                    if(index<0){
                                                                                        item.clientid=event[0].value;
                                                                                        //item.tbleuser=event[0].label;
                                                                                        this.state.shiftListToBeEdited.push(item);
                                                                                    }else{
                                                                                        list[index].clientid=event[0].value;
                                                                                        //list[index].staffid=event[0].label;
                                                                                    }
                                                                                    this.setState({shiftListToBeEdited: list});
                                                                                }
                                                                            }}
                                                                            placeholder="Select Participant Service..."
                                                                        />
                                                                        <i type="button" class="bi  bi-x-circle-fill cancell-icon m-1" onClick={()=>{
                                                                                document.getElementById(`client-${item.id}`).style.display='block'
                                                                                document.getElementById(`edit-client-${item.id}`).style.display='none';
                                                                            }}>Cancel</i> 
                                                                            <i type="button" class="bi  bi-x-circle-fill bi-check2-all m-1 text-success" onClick={()=>{
                                                                                document.getElementById(`client-${item.id}`).style.display='block'
                                                                                document.getElementById(`edit-client-${item.id}`).style.display='none';
                                                                            }}>Done</i>
                                                                    </div>
                                                                </td>
                                                            }
                                                            
                                                            <td>
                                                                <div id={`costperhour-${item.id}`} onClick={()=>{
                                                                        document.getElementById(`edit-costperhour-${item.id}`).style.display='block'
                                                                        document.getElementById(`costperhour-${item.id}`).style.display='none';
                                                                    }}>
                                                                    <i class="bi bi-pencil-square"></i> {`${item.costperhour}`}
                                                                </div>
                                                                <div style={{"display":"none"}} id={`edit-costperhour-${item.id}`}>
                                                                    <input defaultValue ={item.costperhour}
                                                                     onKeyDown={(event)=>{
                                                                         if(event.key=="Enter"){
                                                                            document.getElementById(`costperhour-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-costperhour-${item.id}`).style.display='none';
                                                                         }
                                                                     }}
                                                                     onChange={(event)=>{
                                                                                document.getElementById(`costperhour-${item.id}`).innerHTML = event.target.value;
                                                                                var list= this.state.shiftListToBeEdited;
                                                                                const index= list.findIndex(x=>x.id==item.id);
                                                                                if(index<0){
                                                                                    item.costperhour=event.target.value;
                                                                                    this.state.shiftListToBeEdited.push(item);
                                                                                }else{
                                                                                    list[index].costperhour=event.target.value;
                                                                                }
                                                                                this.setState({shiftListToBeEdited: list});
                                                                                
                                                                        }} type="text" class="form-control border-primary md-col-10" id="costperhour" aria-describedby="emailHelp"/>
                                                                   <div className="md-col-2">
                                                                    <i type="button" class="bi  bi-x-circle-fill cancell-icon m-1" onClick={()=>{
                                                                            document.getElementById(`costperhour-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-costperhour-${item.id}`).style.display='none';
                                                                        }}>Cancel</i> 
                                                                        <i type="button" class="bi  bi-x-circle-fill bi-check2-all m-1 text-success" onClick={()=>{
                                                                            document.getElementById(`costperhour-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-costperhour-${item.id}`).style.display='none';
                                                                        }}>Done</i>
                                                                   </div>
                                                                    
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        :
                                                        ""
                                                    )

                                                }
                                                
                                            </tbody>
                                        </table>
                                    </div>
                            </Modal.Body>
                                <Modal.Footer>
                                <Button variant="dark" onClick={()=>this.onCloseModal()}>
                                    Close
                                </Button>
                                <Button variant="danger" onClick={()=>{this.props.executeDeleteShift(this.state.selectedShiftIds); this.setState({selectedShiftIds:[]})}}>
                                    Delete
                                </Button>
                                <Button variant="success" onClick={()=>{this.props.executeApproveShift(this.state.selectedShiftIds)}}>
                                    Approve
                                </Button>
                                <Button variant="warning" onClick={()=>{this.props.executeConfirmShift(this.state.selectedShiftIds)}}>
                                    Confirm
                                </Button>
                                <Button variant="primary" onClick={()=>{
                                    //Now sending updates to the server
                                    this.props.updateParticipantShifts(this.state.shiftListToBeEdited).then(res=>this.setState({shiftListToBeEdited: []}));
                                    }}>
                                    Save {this.state.shiftListToBeEdited.length>0?`(${this.state.shiftListToBeEdited.length})`:``}
                                </Button>
                            </Modal.Footer>
                            </Tab>
                            {/* <Tab eventKey="new_view" title="New View">
                                <div className="m-4 m-0 row">
                                    <div class="card col-sm-12 col-md-5 col-xl-5 col-lg-5 ml-0 mt-0 mb-2 m-2">
                                        <div class="card-body">
                                            <h5 class="card-title">Card title</h5>
                                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                            <a href="#" class="card-link">Card link</a>
                                            <a href="#" class="card-link">Another link</a>
                                        </div>
                                    </div>
                                    <div class="card col-sm-12 col-md-5 col-xl-5 col-lg-5 ml-0 mt-0 mb-2 m-2">
                                        <div class="card-body">
                                            <h5 class="card-title">Card title</h5>
                                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                            <a href="#" class="card-link">Card link</a>
                                            <a href="#" class="card-link">Another link</a>
                                        </div>
                                    </div>
                                    <div class="card col-sm-12 col-md-5 col-xl-5 col-lg-5 ml-0 mt-0 mb-2 m-2">
                                        <div class="card-body">
                                            <h5 class="card-title">Card title</h5>
                                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                            <a href="#" class="card-link">Card link</a>
                                            <a href="#" class="card-link">Another link</a>
                                        </div>
                                    </div>
                                    <div class="card col-sm-12 col-md-5 col-xl-5 col-lg-5 ml-0 mt-0 mb-2 m-2">
                                        <div class="card-body">
                                            <h5 class="card-title">Card title</h5>
                                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                            <a href="#" class="card-link">Card link</a>
                                            <a href="#" class="card-link">Another link</a>
                                        </div>
                                    </div>
                                </div>
                            </Tab> */}
                            {/* SECOND TAB ON MODAL | PARTICIPANT SHIFTS */}
                            <Tab  eventKey="profile" title="Participant Shift(s)">
                                <Modal.Body>
                                    <div className="table-shifts"> 
                                        <table class="table table-hover">
                                            <thead>
                                                <tr className="participant-shift-row">
                                                <th  scope="col">
                                                    <input
                                                        checked={this.state.selectedShiftIds.length==this.state.shifts.length&&this.state.shifts.length!=0?"checked":""}
                                                        onClick={()=>{
                                                            var list=[];
                                                            if(this.state.selectedShiftIds.length>0){
                                                                this.setState({selectedShiftIds:[]})
                                                            }else{
                                                                this.state.shifts.forEach(element=>{
                                                                    list.push(element.id);
                                                                })
                                                            }
                                                            this.setState({selectedShiftIds:list});
                                                        }}
                                                     type="checkbox">
                                                    </input></th>
                                                <th  scope="col">Date</th>
                                                <th  scope="col">Start</th>
                                                <th scope="col">End </th>
                                                <th scope="col">Service</th>
                                                <th scope="col">{this.state.viewRosterByParticipants?`Staff` : `Client`}</th>
                                                <th  scope="col">Cost</th>
                                                <th  scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.shifts.map(item=>
                                                        item.clientid>0?
                                                        <tr className={!item.approved?"participant-shift-row":item.approved&&!item.confirmed?"participant-shift-row day-shift-approved":"participant-shift-row day-shift-confirmed"}>
                                                            <td>
                                                                <input 
                                                                    checked={this.state.selectedShiftIds.findIndex(x=>x==item.id)>=0?"checked":""} 
                                                                    onClick={(event)=>{
                                                                        var list=this.state.selectedShiftIds;
                                                                        const index=list.findIndex(x=>x==event.target.value);
                                                                        if(index>=0){
                                                                            list = list.slice(0, index).concat(list.slice(index + 1, list.length));
                                                                        }else{
                                                                            list.push(event.target.value);
                                                                        }
                                                                        this.setState({selectedShiftIds:list});
                                                                    }}
                                                                    value={item.id} type="checkbox" >
                                                                </input></td>
                                                            <td>
                                                                <div id={`date-${item.id}`} onClick={()=>{
                                                                        document.getElementById(`edit-date-${item.id}`).style.display='block'
                                                                        document.getElementById(`date-${item.id}`).style.display='none';
                                                                    }}>
                                                                    <i id={`date-${item.id}`} onClick={()=>{}} class="bi bi-pencil-square"></i> {`${moment(item.date).format("ddd")} ${moment(item.date).format("DD")} ${moment(item.date).format("MMM")}`}
                                                                </div>
                                                                <div style={{"display":"none"}} id={`edit-date-${item.id}`}>
                                                                    
                                                                    <input id={item.id} type="date" className="form-control border-primary" defaultValue={item.date}
                                                                        onKeyDown={(event)=>{
                                                                            if(event.key=="Enter"){
                                                                                document.getElementById(`date-${item.id}`).style.display='block'
                                                                                document.getElementById(`edit-date-${item.id}`).style.display='none';
                                                                            }
                                                                        }}
                                                                        onChange={(event)=>{
                                                                            document.getElementById(`date-${item.id}`).innerHTML = `${moment(event.target.value).format("ddd")} ${moment(event.target.value).format("DD")} ${moment(event.target.value).format("MMM")}`;
                                                                            
                                                                            var list= this.state.shiftListToBeEdited;
                                                                                const index= list.findIndex(x=>x.id==item.id);
                                                                                if(index<0){
                                                                                    item.date=event.target.value;
                                                                                    this.state.shiftListToBeEdited.push(item)
                                                                                }else{
                                                                                    list[index].date=event.target.value;
                                                                                }
                                                                                this.setState({shiftListToBeEdited: list});
                                                                        }}
                                                                    ></input>

                                                                        <i type="button" class="bi  bi-x-circle-fill cancell-icon m-1" onClick={()=>{
                                                                            document.getElementById(`date-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-date-${item.id}`).style.display='none';
                                                                        }}>Cancel</i> 
                                                                        <i type="button" class="bi  bi-x-circle-fill bi-check2-all m-1 text-success" onClick={()=>{
                                                                            document.getElementById(`date-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-date-${item.id}`).style.display='none';
                                                                        }}>Done</i>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div id={`starttime-${item.id}`} onClick={()=>{
                                                                        document.getElementById(`edit-starttime-${item.id}`).style.display='block'
                                                                        document.getElementById(`starttime-${item.id}`).style.display='none';
                                                                    }}>
                                                                    <i id={`starttime-${item.id}`} onClick={()=>{}} class="bi bi-pencil-square"></i> {`${item.starttime.split(":")[0]}:${item.starttime.split(":")[1]}`}
                                                                </div>
                                                                <div style={{"display":"none"}} id={`edit-starttime-${item.id}`}>
                                                                    <input defaultValue ={item.starttime} 
                                                                    onKeyDown={(event)=>{
                                                                        if(event.key=="Enter"){
                                                                            document.getElementById(`starttime-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-starttime-${item.id}`).style.display='none';
                                                                        }
                                                                    }}
                                                                     onChange={(event)=>{
                                                                                document.getElementById(`starttime-${item.id}`).innerHTML = event.target.value;
                                                                                
                                                                                var list= this.state.shiftListToBeEdited;
                                                                                const index= list.findIndex(x=>x.id==item.id);
                                                                                if(index<0){
                                                                                    item.starttime=event.target.value;
                                                                                    this.state.shiftListToBeEdited.push(item);
                                                                                }else{
                                                                                    list[index].starttime=event.target.value;
                                                                                }
                                                                                this.setState({shiftListToBeEdited: list});
                                                                                
                                                                        }} type="time" class="form-control border-primary" id="starttime" aria-describedby="emailHelp"/>
                                                                    {/* <div className="row"> */}
                                                                        <i type="button" class="bi  bi-x-circle-fill cancell-icon m-1" onClick={()=>{
                                                                                document.getElementById(`starttime-${item.id}`).style.display='block'
                                                                                document.getElementById(`edit-starttime-${item.id}`).style.display='none';
                                                                            }}>Cancel</i> 
                                                                            <i type="button" class="bi  bi-x-circle-fill bi-check2-all m-1 text-success" onClick={()=>{
                                                                                document.getElementById(`starttime-${item.id}`).style.display='block'
                                                                                document.getElementById(`edit-starttime-${item.id}`).style.display='none';
                                                                            }}>Done</i>
                                                                    {/* </div> */}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div id={`endtime-${item.id}`} onClick={()=>{
                                                                        document.getElementById(`edit-endtime-${item.id}`).style.display='block'
                                                                        document.getElementById(`endtime-${item.id}`).style.display='none';
                                                                    }}>
                                                                    <i id={`endtime-${item.id}`} onClick={()=>{}} class="bi bi-pencil-square"></i> {`${item.endtime.split(":")[0]}:${item.endtime.split(":")[1]}`}
                                                                </div>
                                                                <div style={{"display":"none"}} id={`edit-endtime-${item.id}`}>
                                                                    <input defaultValue={item.endtime}
                                                                     onKeyDown={(event)=>{
                                                                            if(event.key=="Enter"){
                                                                                document.getElementById(`endtime-${item.id}`).style.display='block'
                                                                                document.getElementById(`edit-endtime-${item.id}`).style.display='none';
                                                                            }
                                                                        }}
                                                                     onChange={(event)=>{
                                                                            var list= this.state.shiftListToBeEdited;
                                                                            const index= list.findIndex(x=>x.id==item.id);
                                                                            document.getElementById(`endtime-${item.id}`).innerHTML = event.target.value;
                                                                            if(index<0){
                                                                                item.endtime=event.target.value;
                                                                                this.state.shiftListToBeEdited.push(item);
                                                                            }else{
                                                                                list[index].endtime=event.target.value
                                                                            }
                                                                            this.setState({shiftListToBeEdited: list});
                                                                        }}
                                                                     type="time" class="form-control border-primary" id="starttime" aria-describedby="emailHelp"/>
                                                                    
                                                                    <i type="button" class="bi  bi-x-circle-fill cancell-icon m-1" onClick={()=>{
                                                                            document.getElementById(`endtime-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-endtime-${item.id}`).style.display='none';
                                                                        }}>Cancel</i> 
                                                                        <i type="button" class="bi  bi-x-circle-fill bi-check2-all m-1 text-success" onClick={()=>{
                                                                            document.getElementById(`endtime-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-endtime-${item.id}`).style.display='none';
                                                                        }}>Done</i>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div id={`service-${item.id}`} onClick={()=>{
                                                                    document.getElementById(`edit-service-${item.id}`).style.display='block'
                                                                    document.getElementById(`service-${item.id}`).style.display='none';
                                                                }}>
                                                                    <i id={`service-${item.id}`} onClick={()=>{}} class="bi bi-pencil-square"> </i>{item.tbl_support_service.support_service}
                                                                </div>
                                                                <div style={{"display":"none"}} id={`edit-service-${item.id}`}>
                                                                    <Typeahead
                                                                        className=".form-control-inputs"
                                                                        allowNew
                                                                        id={`edit-service-${item.id}`}
                                                                        onKeyDown={(event)=>{
                                                                            if(event.key=="Enter"){
                                                                                document.getElementById(`service-${item.id}`).style.display='block'
                                                                                document.getElementById(`edit-service-${item.id}`).style.display='none';
                                                                            }
                                                                        }}
                                                                        newSelectionPrefix="Service: "
                                                                        options={this.props.participantServices}
                                                                        onChange={(event)=>{
                                                                            if(event[0]){
                                                                                document.getElementById(`service-${item.id}`).innerHTML = event[0].label;
                                                                                document.getElementById(`service-${item.id}`).style.display='block';
                                                                                document.getElementById(`edit-service-${item.id}`).style.display='none';
                                                                                var list= this.state.shiftListToBeEdited;
                                                                                const index= list.findIndex(x=>x.id==item.id);
                                                                                if(index<0){
                                                                                    item.serviceid=event[0].value;
                                                                                    item.tbl_support_service.support_service=event[0].label;
                                                                                    this.state.shiftListToBeEdited.push(item);
                                                                                }else{
                                                                                    list[index].serviceid=event[0].value;
                                                                                    list[index].tbl_support_service.support_service=event[0].label;
                                                                                }
                                                                                this.setState({shiftListToBeEdited: list});
                                                                            }
                                                                        }}
                                                                        placeholder="Select Particpant Service..."
                                                                    />
                                                                    <i type="button" class="bi  bi-x-circle-fill cancell-icon m-1" onClick={()=>{
                                                                            document.getElementById(`service-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-service-${item.id}`).style.display='none';
                                                                        }}>Cancel</i> 
                                                                        <i type="button" class="bi  bi-x-circle-fill bi-check2-all m-1 text-success" onClick={()=>{
                                                                            document.getElementById(`service-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-service-${item.id}`).style.display='none';
                                                                        }}>Done</i>
                                                                </div>
                                                            </td>
                                                            {
                                                                this.state.viewRosterByParticipants?
                                                                <td>
                                                                    {item.tbluser?
                                                                        <div id={`staff-${item.id}`} onClick={()=>{
                                                                            document.getElementById(`edit-staff-${item.id}`).style.display='block'
                                                                            document.getElementById(`staff-${item.id}`).style.display='none';
                                                                        }}>
                                                                        <i class="bi bi-pencil-square"></i>{item.tbluser.lastname} {item.tbluser.firstname}
                                                                        </div>
                                                                        :
                                                                        <i id={`staff-${item.id}`} onClick={()=>{
                                                                            document.getElementById(`edit-staff-${item.id}`).style.display='block'
                                                                            document.getElementById(`staff-${item.id}`).style.display='none';
                                                                        }} class="bi bi-pencil-square">Asign Staff</i>
                                                                    } 
                                                                    <div style={{"display":"none"}} id={`edit-staff-${item.id}`}>
                                                                        <Typeahead
                                                                            // style={{"display":"none"}}
                                                                            className=".form-control-inputs"
                                                                            allowNew
                                                                            id={`edit-staff-${item.id}`}
                                                                            newSelectionPrefix="Staff: "
                                                                            options={this.props.workers}
                                                                            onKeyDown={(event)=>{
                                                                                if(event.key=="Enter"){
                                                                                    document.getElementById(`staff-${item.id}`).style.display='block'
                                                                                    document.getElementById(`edit-staff-${item.id}`).style.display='none';
                                                                                }
                                                                            }}
                                                                            onChange={(event)=>{
                                                                                if(event[0]){
                                                                                    document.getElementById(`staff-${item.id}`).innerHTML = event[0].label;
                                                                                    document.getElementById(`staff-${item.id}`).style.display='block';
                                                                                    document.getElementById(`edit-staff-${item.id}`).style.display='none';
                                                                                    var list= this.state.shiftListToBeEdited;
                                                                                    const index= list.findIndex(x=>x.id==item.id);
                                                                                    if(index<0){
                                                                                        item.staffid=event[0].value;
                                                                                        this.state.shiftListToBeEdited.push(item);
                                                                                    }else{
                                                                                        list[index].staffid=event[0].value;
                                                                                    }
                                                                                    this.setState({shiftListToBeEdited: list});
                                                                                }
                                                                            }}
                                                                            placeholder="Select Staff Service..."
                                                                        />
                                                                        <i type="button" class="bi  bi-x-circle-fill cancell-icon m-1" onClick={()=>{
                                                                                document.getElementById(`staff-${item.id}`).style.display='block'
                                                                                document.getElementById(`edit-staff-${item.id}`).style.display='none';
                                                                            }}>Cancel</i> 
                                                                            <i type="button" class="bi  bi-x-circle-fill bi-check2-all m-1 text-success" onClick={()=>{
                                                                                document.getElementById(`staff-${item.id}`).style.display='block'
                                                                                document.getElementById(`edit-staff-${item.id}`).style.display='none';
                                                                            }}>Done</i>
                                                                    </div>
                                                                </td>

                                                                :

                                                                <td>
                                                                {item.tblparticipant?
                                                                    <div id={`client-${item.id}`} onClick={()=>{
                                                                        document.getElementById(`edit-client-${item.id}`).style.display='block'
                                                                        document.getElementById(`client-${item.id}`).style.display='none';
                                                                    }}>
                                                                    <i class="bi bi-pencil-square"></i>{item.tblparticipant.lastname} {item.tblparticipant.firstname}
                                                                    </div>
                                                                    :
                                                                    <i id={`client-${item.id}`} onClick={()=>{
                                                                        document.getElementById(`edit-client-${item.id}`).style.display='block'
                                                                        document.getElementById(`client-${item.id}`).style.display='none';
                                                                    }} class="bi bi-pencil-square">Asign Participant</i>
                                                                    } 
                                                                    <div style={{"display":"none"}} id={`edit-client-${item.id}`}>
                                                                        <Typeahead
                                                                            // style={{"display":"none"}}
                                                                            className=".form-control-inputs"
                                                                            allowNew
                                                                            id={`edit-client-${item.id}`}
                                                                            newSelectionPrefix="Participant: "
                                                                            options={this.props.clients}
                                                                            onKeyDown={(event)=>{
                                                                                if(event.key=="Enter"){
                                                                                    document.getElementById(`client-${item.id}`).style.display='block'
                                                                                    document.getElementById(`edit-client-${item.id}`).style.display='none';
                                                                                }
                                                                            }}
                                                                            onChange={(event)=>{
                                                                                if(event[0]){
                                                                                    document.getElementById(`client-${item.id}`).innerHTML = event[0].label;
                                                                                    document.getElementById(`client-${item.id}`).style.display='block';
                                                                                    document.getElementById(`edit-client-${item.id}`).style.display='none';
                                                                                    var list= this.state.shiftListToBeEdited;
                                                                                    const index= list.findIndex(x=>x.id==item.id);
                                                                                    if(index<0){
                                                                                        item.clientid=event[0].value;
                                                                                        //item.tbleuser=event[0].label;
                                                                                        this.state.shiftListToBeEdited.push(item);
                                                                                    }else{
                                                                                        list[index].clientid=event[0].value;
                                                                                        //list[index].staffid=event[0].label;
                                                                                    }
                                                                                    this.setState({shiftListToBeEdited: list});
                                                                                }
                                                                            }}
                                                                            placeholder="Select Participant Service..."
                                                                        />
                                                                        <i type="button" class="bi  bi-x-circle-fill cancell-icon m-1" onClick={()=>{
                                                                                document.getElementById(`client-${item.id}`).style.display='block'
                                                                                document.getElementById(`edit-client-${item.id}`).style.display='none';
                                                                            }}>Cancel</i> 
                                                                            <i type="button" class="bi  bi-x-circle-fill bi-check2-all m-1 text-success" onClick={()=>{
                                                                                document.getElementById(`client-${item.id}`).style.display='block'
                                                                                document.getElementById(`edit-client-${item.id}`).style.display='none';
                                                                            }}>Done</i>
                                                                    </div>
                                                                </td>
                                                            }
                                                            
                                                            <td>
                                                                <div id={`costperhour-${item.id}`} onClick={()=>{
                                                                        document.getElementById(`edit-costperhour-${item.id}`).style.display='block'
                                                                        document.getElementById(`costperhour-${item.id}`).style.display='none';
                                                                    }}>
                                                                    <i class="bi bi-pencil-square"></i> {`${item.costperhour}`}
                                                                </div>
                                                                <div style={{"display":"none"}} id={`edit-costperhour-${item.id}`}>
                                                                    <input defaultValue ={item.costperhour}
                                                                     onKeyDown={(event)=>{
                                                                         if(event.key=="Enter"){
                                                                            document.getElementById(`costperhour-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-costperhour-${item.id}`).style.display='none';
                                                                         }
                                                                     }}
                                                                     onChange={(event)=>{
                                                                                document.getElementById(`costperhour-${item.id}`).innerHTML = event.target.value;
                                                                                var list= this.state.shiftListToBeEdited;
                                                                                const index= list.findIndex(x=>x.id==item.id);
                                                                                if(index<0){
                                                                                    item.costperhour=event.target.value;
                                                                                    this.state.shiftListToBeEdited.push(item);
                                                                                }else{
                                                                                    list[index].costperhour=event.target.value;
                                                                                }
                                                                                this.setState({shiftListToBeEdited: list});
                                                                                
                                                                        }} type="text" class="form-control border-primary md-col-10" id="costperhour" aria-describedby="emailHelp"/>
                                                                   <div className="md-col-2">
                                                                    <i type="button" class="bi  bi-x-circle-fill cancell-icon m-1" onClick={()=>{
                                                                            document.getElementById(`costperhour-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-costperhour-${item.id}`).style.display='none';
                                                                        }}>Cancel</i> 
                                                                        <i type="button" class="bi  bi-x-circle-fill bi-check2-all m-1 text-success" onClick={()=>{
                                                                            document.getElementById(`costperhour-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-costperhour-${item.id}`).style.display='none';
                                                                        }}>Done</i>
                                                                   </div>
                                                                    
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        :
                                                        ""
                                                    )
                                                }
                                                
                                            </tbody>
                                        </table>
                                    </div>
                            </Modal.Body>
                                <Modal.Footer>
                                <Button variant="dark" onClick={()=>this.onCloseModal()}>
                                    Close
                                </Button>
                                <Button variant="danger" onClick={()=>{this.props.executeDeleteShift(this.state.selectedShiftIds); this.setState({selectedShiftIds:[]})}}>
                                    Delete
                                </Button>
                                <Button variant="success" onClick={()=>{this.props.executeApproveShift(this.state.selectedShiftIds)}}>
                                    Approve
                                </Button>
                                <Button variant="warning" onClick={()=>{this.props.executeConfirmShift(this.state.selectedShiftIds)}}>
                                    Confirm
                                </Button>
                                <Button variant="primary" onClick={()=>{
                                    //Now sending updates to the server
                                    this.props.updateParticipantShifts(this.state.shiftListToBeEdited).then(res=>this.setState({shiftListToBeEdited: []}));
                                    }}>
                                    Save {this.state.shiftListToBeEdited.length>0?`(${this.state.shiftListToBeEdited.length})`:``}
                                </Button>
                            </Modal.Footer>
                        </Tab>
                    </Tabs>
                
            </Modal>
        </>
        )
    }

}