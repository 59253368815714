import React from 'react'
import '../assets/nav.css'
import {  Dropdown } from 'react-bootstrap';
import RosterController from '../controllers/roster';
import { Link } from 'react-router-dom';

export default class NavigationBar extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            permissions : []
        }
    }
    componentDidUpdate(prevProps) {
        console.log(this.props.permissions)
        if(prevProps.permissions != this.props.permissions){
            this.setState({permissions : this.props.permissions})
        }
    }
    render() {
        return(
        <div>
            <nav className="navbar navbar-expand-sm navbar-dark bg-primary">
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    {/* On Small Screans */}
                    {/* <div className="d-lg-none d-md-none  d-sm-block text-dark profile m-1 mt-0 mb-0" type="button" >EK</div>
                    <div className="d-lg-none d-md-none d-sm-block text-light fw-bolder">
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                Englon Kavhuru
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Sign Out</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">My Shift(s)</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div> */}
                    <div className="user-profile-sm text-light fw-bolder m-4 mt-0 mb-0">
                            <Dropdown>
                                <Dropdown.Toggle variant="" className="text-light" style={{"font-weight":"bolder"}} id="dropdown-basic">
                                    {RosterController.extractToken().name}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Sign Out</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">My Shift(s)</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    <div className="collapse navbar-collapse" id="mynavbar">
                        <ul className="navbar-nav me-auto">
                            <a className="nav-item ">
                                <a className="nav-link text-light fw-bolder" href={`${RosterController.extractToken().main_system}`}> Tarisa</a>
                            </a>
                            <li className="nav-item ">
                                <Link className="nav-link text-light fw-bolder" to={`/dashboard?name=${RosterController.extractToken().name}&token=${RosterController.extractToken().token}&api=${RosterController.extractToken().api}&main_system=${RosterController.extractToken().main_system}`}> My Dashboard</Link>
                            </li>
                            {
                                this.state.permissions.findIndex(x => x == "dashboard-view")>-1?
                                    <li className="nav-item">
                                        <Link className="nav-link text-light fw-bolder " to={`/roster?name=${RosterController.extractToken().name}&token=${RosterController.extractToken().token}&api=${RosterController.extractToken().api}&main_system=${RosterController.extractToken().main_system}`}>Roster</Link>
                                    </li>
                                    :
                                    ""
                            }
                            {
                                this.state.permissions.findIndex(x => x == "process-timesheets")>-1?
                                    <li className="nav-item">
                                        <Link className="nav-link text-light fw-bolder " to={`/timesheets?name=${RosterController.extractToken().name}&token=${RosterController.extractToken().token}&api=${RosterController.extractToken().api}&main_system=${RosterController.extractToken().main_system}`}>Time Sheets</Link>
                                    </li>
                                    :
                                    ""
                            }
                            {
                                this.state.permissions.findIndex(x => x == "participants-view")>-1?
                                    <li className="nav-item">
                                        <Link className="nav-link text-light fw-bolder" to={`/participants?name=${RosterController.extractToken().name}&token=${RosterController.extractToken().token}&api=${RosterController.extractToken().api}&main_system=${RosterController.extractToken().main_system}`}>Participants</Link>
                                    </li>
                                    :
                                    ""
                            }
                            {
                                this.state.permissions.findIndex(x => x == "insights-view")>-1?
                                    <li className="nav-item">
                                        <Link className="nav-link text-light fw-bolder" to={`/reports?name=${RosterController.extractToken().name}&token=${RosterController.extractToken().token}&api=${RosterController.extractToken().api}&main_system=${RosterController.extractToken().main_system}`}>Insigths</Link>
                                    </li>
                                    :
                                    ""
                            }
                            {
                                this.state.permissions.findIndex(x => x == "systemaudit-view")>-1?
                                    <li className="nav-item">
                                        <Link className="nav-link text-light fw-bolder" to={`/audit?name=${RosterController.extractToken().name}&token=${RosterController.extractToken().token}&api=${RosterController.extractToken().api}&main_system=${RosterController.extractToken().main_system}`}>System Audit</Link>
                                    </li>
                                    :
                                    ""
                            }
                            
                            {/* <li className="nav-item">
                                <Link className="nav-link text-light fw-bolder " to={`/invoices?name=${RosterController.extractToken().name}&token=${RosterController.extractToken().token}&api=${RosterController.extractToken().api}&main_system=${RosterController.extractToken().main_system}`}>Invoices</Link>
                            </li> */}
                            
                            
                            
                        </ul>
                
                        <div className="text-light fw-bolder m-4 mt-0 mb-0 user-profile-lg">
                            <Dropdown>
                                <Dropdown.Toggle variant="" className="text-light" style={{"font-weight":"bolder"}} id="dropdown-basic">
                                    {RosterController.extractToken().name}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Sign Out</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">My Shift(s)</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>                        
                </div>
            </nav>
        </div>
        );
    }
}