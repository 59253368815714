import React from 'react';
import './assets/App.css';
import RoutesConfig from './routes/routes';
export default class App extends React.Component {

  render() {
    return(
      <div style={{"height":`${window.screen.height*0.6}px`}}>
        <RoutesConfig></RoutesConfig>
      </div>
      );
  }
}
