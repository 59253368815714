import React from 'react'
import axios from "axios"
import RosterController from '../controllers/roster';

export default class DashboardController extends React.Component {
    static getTime = async () =>{
        try {
            const res = await axios.get(RosterController.extractToken().api + "shift/time/?token=" + RosterController.extractToken().token);
            return res.data.res;
        }
        catch (error) {
            RosterController.errorHandler(error)
            return error;
        }
    }

    static clockin = async (shiftid) =>{
        try {
            const res = await axios.get(RosterController.extractToken().api + "shift/clockin/?token=" + RosterController.extractToken().token + "&shiftid=" + shiftid);
            RosterController.notifications({message:res.data.res, isError : false});
            return res.data.res;
        }
        catch (error) {
            RosterController.errorHandler(error)
            return error;
        }
    }

    static clockout = async (shiftid) =>{
        try {
            const res = await axios.get(RosterController.extractToken().api + "shift/clockout/?token=" + RosterController.extractToken().token + "&shiftid=" + shiftid);
            RosterController.notifications({message:res.data.res, isError : false});
            return res.data.res;
        }
        catch (error) {
            RosterController.errorHandler(error)
            return error;
        }
    }

    // static transferShift = async (data) =>{
    //     try {
    //         const res = await axios.post(RosterController.extractToken().api + "shift/clockout/?token=" + RosterController.extractToken().token, data);
    //         RosterController.notifications({message:res.data.res, isError : false});

    //         return res.data.res;
    //     }
    //     catch (error) {
    //         RosterController.errorHandler(error)
    //         return error;
    //     }
    // }

    static getTransferedShifts = async () =>{
        try {
            const res = await axios.get(RosterController.extractToken().api + "shift/transfer/shifts?token=" + RosterController.extractToken().token);
            return res.data.res;
        }
        catch (error) {
            RosterController.errorHandler(error)
            return [];
        }
    }

    static getOpenShifts = async () =>{
        try {
            const res = await axios.get(RosterController.extractToken().api + "shift/openshifts?token=" + RosterController.extractToken().token);
            return res.data.res;
        }
        catch (error) {
            RosterController.errorHandler(error)
            return [];
        }
    }

    static saveTimeZone = async (data) =>{
        try {
            const res = await axios.post(RosterController.extractToken().api + "config/?token=" + RosterController.extractToken().token,data);
            RosterController.notifications({message:res.data.res, isError : false});
            return res.data.res;
        }
        catch (error) {
            this.notifications({message:error, isError : true});
            return error;
        }
    }
}