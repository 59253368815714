
import React from 'react';
import ReactECharts from 'echarts-for-react';

export default class Series extends React.Component {
    constructor (props) {
        super(props);
        this.state ={
            options : this.options()
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.timesheet !== this.props.timesheet) {
            var data = this.options();
            data.series[0].data = this.props.timesheet.approved;
            data.series[1].data = this.props.timesheet.completed;
            data.series[2].data = this.props.timesheet.confirmed;
            this.setState({options : data})
          }
      }
      options = () => {
        const options = {
            title: {
                text: 'Shifts',
                subtext: 'Weekly Shift Analytics'
              },
            animation : true,
            animationDuration : 1000,
            legend: {
                data: ['Approved', 'Completed', 'Confirmed']
              },
            toolbox: {
                show: true,
                feature: {
                // dataView: { show: true, readOnly: false },
                // magicType: { show: true, type: ['line'] },
                restore: { show: true },
                saveAsImage: { show: true }
                }
            },
            calculable: true,
            color: ['#87CAA1', '#7796f3', '#ffc107'],
			grid: {right: 8, bottom: 24, left: 36 },
			xAxis: {
			  type: 'category',
			  data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
			},
			yAxis: {
			  type: 'value',
			},
			series: [
			  {
                name: 'Approved',
				data: [0,0,0,0,0,0,0],
				type: 'bar',
				smooth: true,
			  },{
                name: 'Completed',
				data: [0,0,0,0,0,0,0],
				type: 'bar',
				smooth: true,
			  },{
                name: 'Confirmed',
				data: [0,0,0,0,0,0,0],
				type: 'bar',
				smooth: true,
			  }
			],
			tooltip: {
			  trigger: 'axis',
			},
		  };
        return options;
    }

	render(){
		  return <>
			  	<ReactECharts className='mb-4' option={this.state.options} />
		  	</>;
	}
}

