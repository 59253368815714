import React from 'react';
import moment from 'moment';
import '../assets/roster.css'
import RosterController from '../controllers/roster'
import Tools from './subcomponents/timesheetTools.js'
import ShiftModal from '../components/modals/addShiftModal'
import { Button, Modal, Tabs, Tab} from 'react-bootstrap';
import "../assets/shiftModal.css"
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "react-datepicker/dist/react-datepicker.css";
import InvoicesTable from "./subcomponents/invoicesTable";


export default class Timesheets extends React.Component { 
    constructor(props) {
        super(props);
        this.state = {
            weekStart : "",
            weekEnd : "",
            shifts:[],
            shifts_data:[],
            nameSearched: "",
            screenHeight : "0px",
            participantInvoices : [],
            selectedEmployeeId : 0,
        }
    }

    componentDidMount(){
        this.setState({screenHeight : window.innerHeight*0.83 + 'px'});
    }
    searchParticipant = (name)=>{
        let data = this.state.shifts_data.filter(value => {
            return (value.name.toLowerCase().includes(name.toLowerCase()));
        });
        this.setState({shifts: data});
    }
   
    updateShiftState=(data)=>{
        return RosterController.getParticpantShifts(data).then(shifts=>{
            var index = shifts.findIndex(x=>x.clientid == this.state.selectedEmployeeId);
            if(index>=0){
                this.setState({participantInvoices : shifts[index].shifts})
            }
            this.setState({shifts:shifts, shifts_data:shifts});
            return shifts;
        })        
    }
    
    updateWeekStates=(data)=>{
        this.setState({weekStart:data.start, weekEnd: data.end})
    }

    render(){
        return(
            <>
                <div className="mt-3 m-3 mb-0">
                    <Tools 
                        updateWeekStates={this.updateWeekStates} 
                        updateShiftState={this.updateShiftState} 
                        weekStart={this.state.weekStart}
                        weekEnd={this.state.weekEnd}
                    ></Tools>
                </div>

                <div className = "row m-3 mt-1 mb-0">
                    <div  className = "col-2">
                        <input onChange = {(event)=>this.searchParticipant(event.target.value)} style = {{backgroundColor : "#f6f7f8"}} placeholder = "Search Customers..." className = "form-control mb-2  rounded-3" ></input>
                        <div style = {{height: `${this.state.screenHeight}`, overflowX:"scroll"}}>
                            {
                                this.state.shifts.map((item)=>
                                    <div onClick = {() =>this.setState({participantInvoices : item.shifts, selectedEmployeeId : item.clientid})} type = "button" className="card m-1 bg-transparent rounded-3">
                                        <div colSpan="1" className="m-1 cell td-roster">
                                            <div className="text-wrap text-dark "><b>{item.name}</b><br></br><small className="text"><i class="bi bi-calendar-event"></i> {item.totalHoursServiced} <small>Wkly Hrs </small>| <i class="bi bi-currency-dollar"></i>{item.weeklyTotalServiceAmount}</small>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className = "col-9">
                        <InvoicesTable timesheets = {this.state.participantInvoices}/>
                    </div>
                </div>
                    
            </>
      )
    }
}