import React from 'react'
import axios from "axios"
import RosterController from '../controllers/roster';

export default class Settings extends React.Component {
    static connectToXero = async () =>{
        try {
            const res = await axios.get(RosterController.extractToken().api + "xero/connect/?token=" + RosterController.extractToken().token);
            window.location = res.data.res;
        }
        catch (error) {
            RosterController.errorHandler(error)
            return error;
        }
    }

    static disConnectToXero = async () =>{
        try {
            const res = await axios.get(RosterController.extractToken().api + "xero/disconnect/?token=" + RosterController.extractToken().token);
            RosterController.notifications({message:res.data.res, isError : false});
            return res.data.res;
        }
        catch (error) {
            RosterController.errorHandler(error)
            return error;
        }
    }

    static syncEmpoyeeDataWithXero = async () =>{
        try {
            const res = await axios.get(RosterController.extractToken().api + "xero/sync/employees/?token=" + RosterController.extractToken().token);
            RosterController.notifications({message:res.data.res, isError : false});
            return res.data.res;
        }
        catch (error) {
            RosterController.errorHandler(error)
            return error;
        }
    }


    static syncCustomerDataWithXero = async () =>{
        try {
            const res = await axios.get(RosterController.extractToken().api + "xero/sync/contacts/?token=" + RosterController.extractToken().token);
            RosterController.notifications({message:res.data.res, isError : false});
            return res.data.res;
        }
        catch (error) {
            RosterController.errorHandler(error)
            return error;
        }
    }

    static saveTimeZone = async (data) =>{
        try {
            const res = await axios.post(RosterController.extractToken().api + "config/?token=" + RosterController.extractToken().token,data);
            RosterController.notifications({message:res.data.res, isError : false});
            return res.data.res;
        }
        catch (error) {
            RosterController.errorHandler(error)
            return error;
        }
    }
}