import React from 'react';
import Dashboard from '../components/dashboard'

export default class DashboardView extends React.Component {
    render() {
        return (
            <>
                <Dashboard></Dashboard>
            </>
        )
      }
}